import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { customDateFormat } from "../CashAllocation/utils";
import { numberFormatter } from "../../utils/generalFunctions"

export const ViewGenericWorkflowDialog = ({
    workFlowDialogOpen,
    workFlowDialogClose,
    workflowTxnData,
    workflowSelectedName
}) => {

    const writeoffWorkflowTxnArrayData = useMemo(
        () => [
            {
                label: "Policy#",
                value: workflowTxnData?.cash_allocation?.policy_id,
            },
            {
                label: "Allocation Status",
                value: workflowTxnData?.cash_allocation?.allocation_status,
            },
            {
                label: "Bank Txn Id",
                value: workflowTxnData?.bank_txn?.Bank_Transaction_Id,
            },
            {
                label: "Write off Amount",
                value: numberFormatter(workflowTxnData?.writeoff_amt),
            },

            {
                label: "write off Reason",
                value: workflowTxnData?.writeoff_reason,
            },
            {
                label: "Comments",
                value: workflowTxnData?.comments,
            }
        ],
        [workflowTxnData]
    );

    const refundWorkflowTxnArrayData = useMemo(() => [
        {
            label: "Policy#",
            value: workflowTxnData?.cash_allocation?.policy_id,
        },
        {
            label: "Allocation Status",
            value: workflowTxnData?.cash_allocation?.allocation_status,
        },
        {
            label: "Bank Txn Id",
            value: workflowTxnData?.bank_txn?.Bank_Transaction_Id,
        },
        {
            label: "Refund Amount",
            value: numberFormatter(workflowTxnData?.refund_amt),
        },
        {
            label: "Refund Reason",
            value: workflowTxnData?.refund_reason,
        },
        {
            label: "Comments",
            value: workflowTxnData?.comments,
        }
    ],
        [workflowTxnData]
    )

    const correctionTypeWorkflowTxnArrayData = useMemo(() => [
        {
            label: "Policy#",
            value: workflowTxnData?.cash_allocation?.policy_id,
        },
        {
            label: "Allocation Status",
            value: workflowTxnData?.allocation_status,
        },
        {
            label: "Correction Description",
            value: workflowTxnData?.correction_description,
        },
        {
            label: "Correction Type",
            value: workflowTxnData?.correction_type,
        },
    ],
        [workflowTxnData]
    )

    const correctiveTransferWorkflowTxnArrayData = useMemo(() => [
        {
            label: "Policy#",
            value: workflowTxnData?.cash_allocation?.policy_id,
        },
        {
            label: "Allocation Status",
            value: workflowTxnData?.cash_allocation?.allocation_status,
        },
        {
            label: "Bank Txn Id",
            value: workflowTxnData?.bank_txn?.Bank_Transaction_Id,
        },
        {
            label: "Bank Currency",
            value: workflowTxnData?.cash_allocation?.bank_txn?.Currency,
        },
        {
            label: "PT Bank Account Number",
            value: workflowTxnData?.PT_bank_acct_Number,
        },
        {
            label: "Treasury Confirmed Transfer Date",
            value: customDateFormat(workflowTxnData?.treasury_confirmed_transfer_date),
        },
        {
            label: "Transfer to PT Entity Name",
            value: workflowTxnData?.transfer_pt_bank_account_name,
        },
        {
            label: "Transfer Amount",
            value: numberFormatter(workflowTxnData?.transfer_amt),
        },
    ],
        [workflowTxnData]
    )

    const crossAllocationWorkflowTxnArrayData = useMemo(() => [
        {
            label: "Policy#",
            value: workflowTxnData?.cash_allocation?.policy_id,
        },
        {
            label: "Allocation Status",
            value: workflowTxnData?.cash_allocation?.allocation_status,
        },
        {
            label: "Bank Txn Id",
            value: workflowTxnData?.bank_txn?.Bank_Transaction_Id,
        },
        {
            label: "Cross Allocation Amount",
            value: numberFormatter(workflowTxnData?.ca_amt),
        },
        {
            label: "New Allocation Date",
            value: customDateFormat(workflowTxnData?.new_allocation_date),
        },
        {
            label: "Process Date",
            value: customDateFormat(workflowTxnData?.process_date),
        },
        {
            label: "Cross Allocation Change",
            value: workflowTxnData?.ca_change,
        },
        {
            label: "Cross Allocation Impact",
            value: workflowTxnData?.ca_impact,
        },
    ],
        [workflowTxnData]
    )

    const cfiWorkflowTxnArrayData = useMemo(() => [
        {
            label: "Policy#",
            value: workflowTxnData?.cash_allocation?.policy_id,
        },
        {
            label: "Allocation Status",
            value: workflowTxnData?.cash_allocation?.allocation_status,
        },
        {
            label: "Bank Txn Id",
            value: workflowTxnData?.bank_txn?.Bank_Transaction_Id,
        },
        {
            label: "CFI Amount",
            value: numberFormatter(workflowTxnData?.cfi_amt),
        },
        {
            label: "CFI Impact",
            value: workflowTxnData?.cfi_impact,
        },
        {
            label: "Request Date",
            value: customDateFormat(workflowTxnData?.request_date),
        },
        {
            label: "Process Date",
            value: customDateFormat(workflowTxnData?.process_date),
        },
        {
            label: "Approved Date",
            value: customDateFormat(workflowTxnData?.approved_date),
        },
        {
            label: "Approver Name",
            value: workflowTxnData?.aprover_name,
        },
        {
            label: "CFI Change",
            value: workflowTxnData?.cfi_change,
        },
    ],
        [workflowTxnData]
    )

    const paymentNoVarianceWorkflowTxnArrayData = useMemo(() => [
        {
            label: "Policy#",
            value: workflowTxnData?.cash_allocation?.policy_id,
        },
        {
            label: "Allocation Status",
            value: workflowTxnData?.cash_allocation?.allocation_status,
        },
        {
            label: "Bank Txn Id",
            value: workflowTxnData?.bank_txn?.Bank_Transaction_Id,
        },
        {
            label: "Request Date",
            value: customDateFormat(workflowTxnData?.request_date),
        },
        {
            label: "Process Date",
            value: customDateFormat(workflowTxnData?.process_date),
        },
        {
            label: "Approved Date",
            value: customDateFormat(workflowTxnData?.approved_date),
        },
        {
            label: "Approver Name",
            value: workflowTxnData?.aprover_name,
        },
    ],
        [workflowTxnData]
    )

    const workflowDialogTitle = () => {
        let title = "";
        switch (workflowSelectedName) {
            case "WF_WRITEOFF":
                title = "Write off";
                break;
            case "WF_REFUND":
                title = "Refund";
                break;
            case "WF_CORRECTION_TYPES":
                title = "Correction Types";
                break;
            case "WF_CORRECTIVE_TRANSFER":
                title = "Corrective Transfer";
                break;
            case "WF_CROSS_ALLOCATION":
                title = "Cross Allocation";
                break;
            case "WF_CFI":
                title = "CFI"
                break;
            default:
                break;
        }
        return title;
    }

    const getDisplayData = () => {
        let dataDetails = [];

        switch (workflowSelectedName) {
            case "WF_WRITEOFF":
                dataDetails = writeoffWorkflowTxnArrayData;
                break;
            case "WF_REFUND":
                dataDetails = refundWorkflowTxnArrayData;
                break;
            case "WF_CORRECTION_TYPES":
                dataDetails = correctionTypeWorkflowTxnArrayData;
                break;
            case "WF_CORRECTIVE_TRANSFER":
                dataDetails = correctiveTransferWorkflowTxnArrayData;
                break;
            case "WF_CROSS_ALLOCATION":
                dataDetails = crossAllocationWorkflowTxnArrayData;
                break;
            case "WF_CFI":
                dataDetails = cfiWorkflowTxnArrayData;
                break;
            case "WF_PAYMENT_NOVARIANCE":
                dataDetails = paymentNoVarianceWorkflowTxnArrayData;
                break;
            default:
                break;
        }

        return dataDetails;
    }

    return (
        <Dialog open={workFlowDialogOpen} onClose={() => workFlowDialogClose(false)} fullWidth sx={{
            "& .MuiDialog-paper": {
                maxWidth: "800px"
            }
        }}>
            <DialogTitle style={{ background: "#FCFCFC", fontWeight: 750, fontSize: "20px" }}>
                {workflowDialogTitle()}
            </DialogTitle>

            <DialogContent>
                <Grid container sx={{ marginTop: "20px" }}>
                    {getDisplayData().map(({ label, value }, i) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            key={label}
                            paddingLeft={i % 2 ? 2 : 0}
                            paddingRight={i % 2 ? 0 : 2}
                            marginBottom={2}
                        >
                            <span>{label} </span>
                            <span style={{ fontWeight: "bold" }}>: {value}</span>
                        </Grid>
                    ))}
                </Grid>

                <DialogActions>
                    <Button onClick={() => workFlowDialogClose(false)}>Close</Button>
                </DialogActions>

            </DialogContent>

        </Dialog>
    )
}