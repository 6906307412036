import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CashTransfer } from "../../Services/api";
import instance from "../../redux/api";
import { getAccountsList } from "../../redux/slice/bankStatmentTransactionsSlice";
import { CustomLoader } from "../CustomLoader";
import UpdateGenericWorkflowDialog from "../Workflow/UpdateGenericWorkflowDialog";
import { CommonSelect } from "../common";
import { inputStyleObj } from "./WriteOff";
import { useBankDetailsListForFilter, useCorrectiveTrf, useCurrencies } from "./hooks";
import { customDateFormat } from "./utils";
import { numberFormatter } from "../../utils/generalFunctions";

const History = ({ fixedDetails, history }) => {
  const [
    openUpdateGenericCorrectiveTransferWorkflowDialog,
    setOpenUpdateGenericCorrectiveTransferWorkflowDialog,
  ] = useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");
  const [currentTxnWorkflowDetails, setCurrentTxnWorkflowDetails] = useState(
    {}
  );

  const handleUpdateGenericWorkflowDialog = () => {
    setOpenUpdateGenericCorrectiveTransferWorkflowDialog(false);
  };
  return (
    <div className="cash-allocation-dialogue-container">
      <Grid container>
        {fixedDetails.map(({ label, value }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            key={label}
            paddingRight={i % 2 ? 2 : 0}
            marginBottom={2}
          >
            <span style={{ fontWeight: label === "Policy#" ? 800 : 400 }}>
              {label}{" "}
            </span>
            <span style={{ fontWeight: "bold" }}>: {value}</span>
          </Grid>
        ))}
      </Grid>
      <TableContainer sx={{ height: 275, overflow: "auto" }}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Requested On
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                PT Bank A/c#
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Ccy
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Amt
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Treasury Transfer Date
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                WF Status
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {history.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell className="table-cell">
                  {customDateFormat(row?.request_date)}
                </TableCell>
                <TableCell className="table-cell">
                  {row?.PT_bank_acct_Number}
                </TableCell>
                <TableCell className="table-cell">{row?.bank_curr}</TableCell>
                <TableCell className="table-cell">
                  {numberFormatter(row?.transfer_amt)}
                </TableCell>
                <TableCell className="table-cell" sx={{ textAlign: "center" }}>
                  {row?.treasury_confirmed_transfer_date
                    ? customDateFormat(row?.treasury_confirmed_transfer_date)
                    : ""}
                </TableCell>
                <TableCell className="table-cell">
                  {row?.workflow?.workflow_status}
                </TableCell>
                <TableCell className="table-cell">
                  <IconButton>
                    <SettingsTwoToneIcon
                      onClick={() => {
                        setCurrentWorkflowName(
                          row?.workflow?.workflow_json_data?.workflow_name ?? ""
                        );
                        setCurrentTxnWorkflowDetails(row?.workflow ?? {});
                        setOpenUpdateGenericCorrectiveTransferWorkflowDialog(
                          true
                        );
                      }}
                      sx={{ fontSize: "25px", color: "#3b3f44" }}
                    />
                  </IconButton>
                  <IconButton>
                    <FileDownloadOutlinedIcon
                      sx={{ fontSize: "25px", color: "blue" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openUpdateGenericCorrectiveTransferWorkflowDialog && (
        <UpdateGenericWorkflowDialog
          openUpdateGenericWorkflowDialog={
            openUpdateGenericCorrectiveTransferWorkflowDialog
          }
          handleUpdateGenericWorkflowDialog={handleUpdateGenericWorkflowDialog}
          workflowName={currentWorkflowName}
          currentWorkflowDetails={currentTxnWorkflowDetails}
        />
      )}
    </div>
  );
};

const CorrectiveTransfer = ({
  allocation,
  transaction,
  handleClose,
  currencies,
  isAutoInitiation = false,
}) => {
  // Global Loader
  const [isLoading, setIsLoading] = useState(false);
  const [isAutoInitiationTransfer, setIsAutoInitiationTransfer] = useState(isAutoInitiation);

  // fetch account number list based on entity and settlement currency
  const [selectedAccount, setSelectedAccount] = useState("");

  let filterBankDetailsPayload = {
    "entity_number": allocation?.policy_fk?.Producing_Entity,
    "currency": allocation?.settlement_currency ?? ""
  };
  let accountNumberList = useBankDetailsListForFilter(filterBankDetailsPayload);

  // Updating selectedAccount on the basis of accountNumberList
  useEffect(() => {
    if (accountNumberList.length === 1) {
      setSelectedAccount(accountNumberList[0].account_number); // Auto-select if only one account
      setFormData({
        ...formData,
        ["PT_bank_acct_Number"]: accountNumberList[0].account_number ?? "",
      });
    }
  }, [accountNumberList]);

  const handleAccountDropdownChange = (name, value, reason) => {
    setSelectedAccount(value);
    if (reason === "clear") {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    setFormData({
      ...formData,
      [name]: value ?? "",
    });
  };

  // Auto Corrective Transfer comment Initialisation
  useEffect(() => {
    if (isAutoInitiationTransfer && !formData.comments) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        comments: "Auto Corrective Transfer Initiated. Please Verify details and Submit.",
      }));
    }
  }, [isAutoInitiationTransfer]);  

  // Handling currencies case for Auto Corrective Transfer Initiation
  let currenciesList = useCurrencies();
  if (!currencies.length) {
    if (allocation?.settlement_currency) {
      currencies.push({
        "currency_code": allocation?.settlement_currency
      });
    } else {
      currencies = currenciesList;
    }
  }

  const { history, formData, setFormData, submit, isApiInProgress, loadHistory } = useCorrectiveTrf(
    allocation,
    transaction
  );

  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [transferPTBankAccountNameList, setTransferPTBankAccountNameList] =
    useState([]);
  const fixedDetails = useMemo(
    () => [
      {
        label: "Policy#",
        value: allocation?.policy_id,
      },
      {
        label: "Receiving Entity",
        value: allocation?.bank_txn?.bank_details?.entity_number,
      },
      {
        label: "Producing Entity",
        value: allocation?.policy_fk?.Producing_Entity,
      },
      {
        label: "System",
        value: allocation?.allocation_systemid,
      },
      {
        label: "Txn Date",
        value: customDateFormat(allocation?.bank_txn?.Payment_Receive_Date),
      },
      {
        label: "Status",
        value: allocation?.allocation_status,
      },
      {
        label: "Bank A/c#",
        value: allocation?.bank_txn?.bank_details?.account_number,
      },
      {
        label: "Bank Ccy",
        value: transaction?.Bank_Currency_Code,
      },
      {
        label: "Receivable Amt",
        value: numberFormatter(allocation?.receivable_amt ?? 0),
        
      },
    ],
    [
      allocation?.allocation_status,
      allocation?.allocation_systemid,
      allocation?.bank_txn?.bank_details?.account_number,
      allocation?.bank_txn?.Payment_Receive_Date,
      allocation?.receivable_amt,
      allocation?.policy_id,
      formData?.producing_entity_bankid,
      transaction?.Bank_Currency_Code,
      allocation?.bank_txn?.bank_details?.entity_number
    ]
  );

  const accountsList = useSelector(
    (state) => state?.bankStatmentTransactions?.accountsList
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAutoInitiationTransfer) {
      setShowHistory(!!history.length);
    }
  }, [history?.length]);

  const handleCloseDialog = useCallback(() => {
    if (isAutoInitiationTransfer) {
      window.location.reload();
    }
    setIsDialogOpen(false);
    handleClose("");
  }, [setIsDialogOpen]);

  const handleSubmitAssing = useCallback(
    async (e) => {
      e.preventDefault();

      // Setting auto initiation flag value
      formData.is_auto_initiated = false;
      if(isAutoInitiationTransfer) {
        formData.is_auto_initiated = true;
      }

      const submitResponse = await submit();
      if(submitResponse && ("shouldShowHistory" in submitResponse) && submitResponse.shouldShowHistory) {
        setShowHistory(true);
        setFormData("");
        loadHistory();
        setIsAutoInitiationTransfer(false);
      }
    },
    [setIsDialogOpen, submit]
  );

  const handleInputChange = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  const handleDropdownChange = (name, value, reason) => {
    if (reason === "clear") {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    setFormData({
      ...formData,
      [name]: value ?? "",
    });
  };

  const getTransferPTEntityNameList = async () => {
    const resp = await instance.get(CashTransfer);
    if (resp.status === 200) {
      const sortedData = resp.data.sort((a, b) => b.id - a.id);
      setTransferPTBankAccountNameList([...sortedData]);
    } else {
      setTransferPTBankAccountNameList([]);
    }
  };

  const [producingEntityData, setProducingEntityData] = useState([]);

  useEffect(() => {
    const fetchProducingEntityData = async () => {
      try {
        const response = await instance.get('documents/entity/');
        setProducingEntityData(response.data);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };
    fetchProducingEntityData();
  }, []);

  useEffect(() => {
    getTransferPTEntityNameList();
  }, []);

  useEffect(() => {
    dispatch(getAccountsList());
  }, []);

  // const bankAccountNumber = isNaN(parseInt(formData.PT_bank_acct_Number))
  //   ? ""
  //   : parseInt(formData.PT_bank_acct_Number);

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "700px",
          },
        }}
        onBackdropClick="false"
      >
        <form onSubmit={handleSubmitAssing}>
          <DialogTitle
            style={{
              background: "#FCFCFC",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "50px",
            }}
          >
            <Typography sx={{ fontSize: 20 }}>Corrective Transfer</Typography>
            <Typography
              sx={{ fontSize: 15, fontWeight: 750, marginLeft: "30px" }}
            >
              Policy#: {allocation?.policy_id}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {showHistory && !isAutoInitiationTransfer ? (
              <History
                setShowHistory={setShowHistory}
                fixedDetails={fixedDetails}
                history={history}
              />
            ) : (
              <>
                <Grid container>
                  {fixedDetails.map(({ label, value }, i) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={label}
                      paddingLeft={i % 2 ? 2 : 0}
                      paddingRight={i % 2 ? 0 : 2}
                      marginBottom={2}
                    >
                      <span>{label} </span>
                      <span style={{ fontWeight: "bold" }}>: {value}</span>
                    </Grid>
                  ))}
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6} paddingLeft={2}>
                    <p style={{ fontSize: 13, color: "#17191B" }}>
                      Transfer to PT Entity Name
                    </p>
                    <CommonSelect
                      placeholder="Enter Transfer to PT Entity Name"
                      name="transfer_pt_bank_account_name"
                      value={formData?.transfer_pt_bank_account_name}
                      handleChange={(event, value, reason) =>
                        handleDropdownChange(
                          "transfer_pt_bank_account_name",
                          value,
                          reason,
                          event
                        )
                      }
                      options={
                        producingEntityData?.map((option) => option?.entity_divisions) ||
                        []
                      }
                      required = {true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} paddingLeft={2}>
                    <p style={{ fontSize: 13, color: "#17191B" }}>
                      PT Bank Account Number
                    </p>
                    <CommonSelect
                      placeholder="Enter bank account no"
                      name="PT_bank_acct_Number"
                      value={selectedAccount}
                      handleChange={(event, value, reason) =>
                        handleAccountDropdownChange(
                          "PT_bank_acct_Number",
                          value,
                          reason,
                          event
                        )
                      }
                      options={
                        accountNumberList?.map((option) => option?.account_number) ||
                        []
                      }
                      required = {true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} paddingLeft={2}>
                    <p style={{ fontSize: 13, color: "#17191B" }}>
                      Currency
                    </p>
                    <CommonSelect
                      placeholder="Enter Currency"
                      name="bank_curr"
                      value={formData?.bank_curr}
                      handleChange={(event, value, reason) =>
                        handleDropdownChange(
                          "bank_curr",
                          value,
                          reason,
                          event
                        )
                      }
                      options={
                        currencies?.map((option) => option?.currency_code) ||
                        []
                      }
                      required = {true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} paddingLeft={2}>
                    <p style={{ fontSize: 13, color: "#17191B" }}>
                      Treasury Confirmed Transfer Date
                    </p>

                    <Input
                      type="date"
                      style={{ ...inputStyleObj }}
                      name="treasury_confirmed_transfer_date"
                      value={formData?.treasury_confirmed_transfer_date}
                      onChange={handleInputChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} paddingLeft={2}>
                    <p style={{ fontSize: 13, color: "#17191B" }}>Comments</p>
                    <TextareaAutosize
                      style={{
                        height: 50,
                        width: "100%",
                      }}
                      name="comments"
                      value={isAutoInitiationTransfer ? "Auto Corrective Transfer Initiated. Please Verify details and Submit." : formData.comments}
                      onChange={handleInputChange}
                      placeholder="Write details here"
                      required={true}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                setShowHistory(!showHistory);
                setIsAutoInitiationTransfer(false);
              }}
              color={showHistory ? "warning" : "inherit"}
              variant={showHistory ? "contained" : "outlined"}
              sx={{ borderRadius: 100 }}
              disabled={isAutoInitiationTransfer}
            >
              {showHistory ? "Add" : "History"}
            </Button>
            <Button
              onClick={handleCloseDialog}
              color="inherit"
              variant="outlined"
              sx={{ borderRadius: 100, border: "1px solid #BCBDBF" }}
              disabled={isAutoInitiationTransfer}
            >
              Close
            </Button>
            {!showHistory && (
              <Button
                variant="contained"
                color="warning"
                sx={{ borderRadius: 100 }}
                type="submit"
                disabled={isApiInProgress}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </form>

        {isLoading && <CustomLoader />}
      </Dialog>
    </>
  );
};

export default CorrectiveTransfer;
