import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CommonButton } from '../../Components/common/commonButton';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { Box, Grid, Stack, TextField, Typography, FormLabel, Tooltip } from "@mui/material";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { customDateFormat, getPaginationCount } from "../CashAllocation/utils";
import format from "date-fns/format";
import { CommonPaginationContainer } from "../common";
import { getAccountingMonths } from "../../Services/api";
import instance from "../../redux/api";
import AddEditAccountingMonth from './AddEditAccountingMonth';
import { PersonPinCircle } from '@mui/icons-material';
import { Edit } from '../../assets/icons';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { px } from 'framer-motion';
import DialogBox from "../common/dialog";
// import { CommonSelect } from '../../';

const AccountingMonthListStyle = {
    color: 'white',
    backgroundColor: '#FF5A01',
    borderRadius: '20px',
    marginRight: '10px',
  };

const AccountingMonthList = () => {
    
    const toggle = useSelector((state) => state.toggleSideMenu);
    const [accountingMonth, setAccountingMonth] = useState([])
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

    const classes = useStyles();


    const [paginationCount, setPaginationCount] = useState(0);
    const [pageState, setPageState] = useState({
        isLoading: true,
        data: [],
        total: 0,
        page: 1,
        pageSize: 25,
      });
    const [isOpenAccountingDialog, setIsOpenAccountingDialog] = useState(false);
    const [operationMode, setOperationMode] = useState();
    const [idToDelete, setIdToDelete] = useState(null);


    // skip 0 means page 1
    const currentSkipNumber = () => {
      return pageState?.page === 1
        ? 0
        : pageState?.page === 0
          ? pageState?.page
          : pageState?.page - 1;
    };

    useEffect(() => {
      setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
    }, [pageState?.total]);

    useEffect(() => {
        loadData();
    }, [pageState?.pageSize, pageState?.page]);

    const loadData = async () => {
      try {
        // set loading indicator
        setPageState((old) => ({
          ...old,
          isLoading: true
        }));
        let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;
        if (isSearchPerformed) {
          const getSearchQueryParam = searchQueryParams();
          if (getSearchQueryParam) {
            queryParams = `${queryParams}${getSearchQueryParam}`;
          }
        }
  
        const response = await instance.get(`${getAccountingMonths}?${queryParams}`);

        console.log("Response : ", response)
  
        // set loading indicator
        setPageState((old) => ({
          ...old,
          isLoading: false
        }));
  
        if (response?.data) {
          setPageState((old) => ({
            ...old,
            total: response?.data?.count,
            data: response?.data?.results ?? [],
          }));
          setPaginationCount(Math.floor(response?.data?.count / pageState?.pageSize));
        } else {
          setPageState((old) => ({
            ...old,
            total: response?.data?.count,
            data: [],
          }));
          setPaginationCount(0);
        }
      } catch (err) {
        setPageState((old) => ({
          ...old,
          isLoading: false
        }));
        setPaginationCount(0);
        console.error("err", err);
      }
    }

    const searchQueryParams = () => {
      return ""
    }
    
    const container = {
        width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
        display: "flex",
        flexDirection: "column",
        gap: 8,
    };
    
    const AccountingMonthListBox = {
        display: "flex",
        justifyContent: "space-between",
        height: 32,
        fontWeight: "400",
        marginTop: 10,
        marginBottom: 8,
    };

    const columns = [
        {
            field: "accounting_month_date",
            headerName: "Accounting Month",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
              <span>
                {params?.value ? format(params?.value, "MMM-yyyy") : ""}
              </span>
            ),
        },
        {
            field: "accounting_month_start_date",
            headerName: "Start Date",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
              <span>
                {params?.value ? format(params?.value, "dd-MMM-yyyy") : ""}
              </span>
            ),
        },
        {
            field: "accounting_month_end_date",
            headerName: "End Date",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
              <span>
                {params?.value ? format(params?.value, "dd-MMM-yyyy") : ""}
              </span>
            ),
        },
        {
            field: "created_by",
            headerName: "Created By",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
              <span>
                {params?.value?.user_name }
              </span>
            ),
        },
        {
            field: "created_at",
            headerName: "Created At",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
              <span>
                {params?.value ? format(params?.value,'dd-MM-yyyy') : ""}
              </span>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
              <>
                <EditIcon
                style={{ marginRight: 8, cursor: "pointer" }}
                titleAccess="Edit"
                onClick={() => handleEditMonth(params.row)}
                />
              
                <DeleteIcon 
                  style={{ marginRight: 8, cursor: "pointer", color: "red" }}
                  titleAccess="Delete"
                  onClick={() => handleDeleteMonth(params.row)}
                />
              </>
              
            ),
        }
    ];

    // const updateAccountingMonth = (data, mode) => {
    //   loadData()
    // }
    
    const handleAddBtnClick = () => {
      setIsOpenAccountingDialog(true);
      setOperationMode("ADD");
    }

    const handleEditMonth = (row) => {
      console.log("Row to edit : ", row)
      setAccountingMonth(row)
      setIsOpenAccountingDialog(true);
      setOperationMode("EDIT");
    }


    const handleDeleteCancelled = () => {
      setOpenDeleteConfirmDialog(false);
      setIdToDelete(null);
    };
  
    const handleDeleteConfirmed = () => {
      instance.delete(`${getAccountingMonths}${idToDelete}/`)
        .then((response) => {
          setOpenDeleteConfirmDialog(false);
          setIdToDelete(null);

          toast.success("Accounting Month deleted successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          setPageState((old) => {
            const newData = old.data.filter((item) => item.id !== idToDelete)
            setPaginationCount(
              getPaginationCount(newData.length, old.pageSize)
            );
            return { ...old, data: newData, total: newData.length };
          });

        }).catch((error) => {
          setOpenDeleteConfirmDialog(false);
          setIdToDelete(null);
          console.log("Error From API : ", error)
          if(typeof error.data.message === 'string'){
            toast.error(error.data.message ?? "Unable to delete Accounting Month!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            })
          }
          else {

            error.data.message.forEach(message => 
              toast.error(message ?? "Unable to delete Accounting Month!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
            )
          }
        })
    };

    const handleDeleteMonth = (row) => {
      setOpenDeleteConfirmDialog(true);
      setIdToDelete(row.id);
    }

  return (

    
    // <div style={container}>
      <Box
      sx={{
        width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
        height: "525px",
      }}>
        <div style={AccountingMonthListBox}>
            <span style={{ color: "#FF5A01", fontSize: "24px" }}>Accounting Month List </span>
            <CommonButton
                text="Add Accounting Month"
                handleClick={handleAddBtnClick}
                sx={AccountingMonthListStyle}
                hoverColor="#FF5A01"
                disabled={pageState?.isLoading}
            />
        </div>
        <div
          style={{
            height: "calc(100vh - 302px)",
            position: "relative",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <DataGrid
            className={classes.pagination}
            loading={pageState?.isLoading}
            rows={pageState?.data ?? []}
            columns={columns}
            rowCount={pageState?.total}
            keepNonExistentRowsSelected
            disableSelectionOnClick
            pagination
            paginationMode="server"
            getRowId={(row) => row?.id}
            page={pageState.page - 1}
            pageSize={pageState?.pageSize ?? 25}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={(newPageSize) => {
              return setPageState((old) => {
                setPaginationCount(
                  getPaginationCount(pageState?.total, newPageSize)
                );
                return { ...old, pageSize: newPageSize, page: 1 };
              });
            }}
            sx={{
              "& .MuiDataGrid-columnHeader[data-field='Actions']": {
                minWidth: toggle?.isOpen
                  ? "315.137px !important"
                  : "366px !important",
              },
            }}
            components={{
              Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Accounting Month available
                </Stack>
              ),
            }}
          />
          <Stack
            style={{
              position: "absolute",
              bottom: "8px",
            }}
          >
            <CommonPaginationContainer
              count={paginationCount + 1}
              pageState={pageState}
              setPageState={setPageState}
            />
          </Stack>
          {isOpenAccountingDialog && (
            <AddEditAccountingMonth
              operationMode={operationMode}
              setIsOpenAccountingDialog={setIsOpenAccountingDialog}
              accountingMonth={accountingMonth}
              updateAccountingMonth={loadData}
              latestMonth={pageState.data[0] ?? {}}
            />
          )}

        <DialogBox
          title="Delete Confirmation"
          body={`Are you sure you want to delete this Accounting Month?`}
          confirmText="Delete"
          isOpen={openDeleteConfirmDialog}
          onCancel={handleDeleteCancelled}
          onConfirm={handleDeleteConfirmed}
          // isDisableConfirm={isDeleteInProgress}
        />
                
        </div>
      </Box>
    // </div>
  )
}

export default AccountingMonthList
