import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import { toast } from "react-toastify";
import { Grid, FormLabel, TextField, Checkbox, Collapse, CircularProgress, Tooltip, IconButton } from "@mui/material";
import instance from "../../redux/api";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getWorkflowList, userManagementGet, workstepUserUpdate } from "../../Services/api";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import './workflow.css';
import { AutoCompleteSelect } from "./AutoCompleteSelect";
import DialogBox from "../common/dialog";
import CloseIcon from "@mui/icons-material/Close";
import { maxWidth } from "@mui/system";

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};

export default function WorkflowContainer() {
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const [row, setRow] = useState({ user: "" });
  const [selectedWorkstep, setSelectedWorkstep] = useState({
    selectedWorkflowId: "",
    selectedWorkstepId: "",
    selectedWorkflowName: "",
    selectedWorkstepName: ""
  });
  const [deleteUser, setDeleteUser] = useState({
    workstepId: "",
    userId: ""
  });
  const [tableRows, setTableRows] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
  const [isOpenAddUserDialog, setIsOpenAddUserDialog] = useState(false);
  const [activeUsersList, setActiveUsersList] = useState([]);
  const [open, setOpen] = useState(false);

  const UserAuthorisation =
    userData?.user_permissions?.permissions_list['Admin Table Maintenance'];

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  useEffect(() => {
    getActiveUsers();
  }, []);


  useEffect(() => {
    loadData();
  }, []);

  const getActiveUsers = async () => {
    const response = await instance.get(`${userManagementGet}?status=Active`);
    setActiveUsersList(response.data && response.data.length ? response.data : [])
  }

  const loadData = async () => {
    try {
      setIsApiCallInprogress(true);
      const resp = await instance.get(getWorkflowList);
      setIsApiCallInprogress(false);
      if (resp.status === 200) {
        const sortedData = resp.data.sort((a, b) => b.id - a.id);
        setTableRows(sortedData);
        const columnKeys = columns.map(column => column.field);
        const transformedData = sortedData?.map(item =>
          columnKeys.map(key => item[key] !== undefined ? item[key] : null)
        );
        setTransformedData(transformedData);
      } else {
        setTableRows([]);
        setTransformedData([]);
      }
    } catch (err) {
      setIsApiCallInprogress(false);
      console.error("err", err);
    }
  };

  const handleCloseAddUserDialog = () => {
    setIsOpenAddUserDialog(false);
    setRow({ ...row, user: "" });
    setSelectedWorkstep({
      selectedWorkflowId: "",
      selectedWorkstepId: "",
      selectedWorkflowName: "",
      selectedWorkstepName: ""
    });
  }

  const handleCloseDeleteUserDialog = () => {
    setOpen(false);
    setDeleteUser({
      workstepId: "",
      userId: ""
    });
  }

  const cleanupTransactionData = () => {
    handleCloseAddUserDialog();
    handleCloseDeleteUserDialog();
    loadData();
  };

  const handleAddUser = async () => {
    try {
      const selectedUserId = row.user.id;
      const worksteps = tableRows?.find(row => row.id === selectedWorkstep.selectedWorkflowId)?.workflow_step || []
      const workstep = worksteps?.find(row => row.id === selectedWorkstep.selectedWorkstepId) || {}
      const userIds = workstep?.user?.map(user => user.id) || []

      if (userIds.includes(selectedUserId)) {
        toast.error("User already exists in workstep.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return
      }

      const payload = { user_id: row.user.id };
      const response = await instance.patch(`${workstepUserUpdate}${selectedWorkstep.selectedWorkstepId}/`, payload);
      if (response) {
        toast.success("User added successfully !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        cleanupTransactionData();
      }
    }
    catch (err) {
      toast.error("User addition failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      handleCloseAddUserDialog()
    }
  }

  const confirmDelete = (workstepId, userId, userLength) => {
    if (userLength <= 1) {
      toast.error("Atleast one user should remain in the workstep", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    setDeleteUser({
      workstepId: workstepId,
      userId: userId
    });
    setOpen(true);
  }

  const handleDeleteUserConfirmed = async () => {
    try {
      let queryParams = ``;
      if (deleteUser.userId) {
        queryParams = `?user_id=${deleteUser.userId}`
      }

      const response = await instance.delete(`${workstepUserUpdate}${deleteUser.workstepId}/${queryParams}`);
      if (response) {
        toast.success("User deleted successfully !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      cleanupTransactionData();
    }
    catch (err) {
      toast.error("User deletion failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      handleCloseDeleteUserDialog()
    }
  }

  const handleDeleteUserCancelled = () => {
    handleCloseDeleteUserDialog();
  }

  const handleUserChange = (event, value, reason) => {
    if (reason === "clear") {
      setRow({ ...row, user: "" })
    }
    else {
      setRow({ ...row, user: value })
    }
  }

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: "none", // Disable default selectable rows
    filterType: "dropdown",
    pagination: false,
    expandableRows: true,
    expandableRowsHeader: false,
    download: false,
    print: false,
    search: false,
    filter: false,
    viewColumns: false,
    serverSide: true,
    selectToolbarPlacement: 'none',
    renderExpandableRow: (rowData, rowMeta) => renderExpandableRowForWorkflowSteps(rowData, rowMeta),
    customToolbarSelect: () => {
      return isApiCallInprogress ? <CircularProgress size={24} /> : null;
    },
    textLabels: {
      body: {
        noMatch: isApiCallInprogress ? <CircularProgress size={24} /> : "Sorry, no data found",
      },
    },
  };

  const renderExpandableRowForWorkflowSteps = (rowData, rowMeta) => {
    const rowId = rowData[0];
    const worksteps = tableRows.find(row => row.id === rowId)?.workflow_step || [];
    const workstepColumnKeys = workstep_columns.map(column => column.field);
    const workstepTransformedData = worksteps?.map(item =>
      workstepColumnKeys.map(key => item[key] !== undefined ? item[key] : null)
    );

    const workstep_options = {
      filter: true,
      onFilterChange: (changedColumn, filterList) => {
        console.log(changedColumn, filterList);
      },
      selectableRows: "none", // Disable default selectable rows
      filterType: "dropdown",
      pagination: false,
      expandableRows: true,
      expandableRowsHeader: false,
      download: false,
      print: false,
      search: false,
      filter: false,
      viewColumns: false,
      serverSide: true,
      selectToolbarPlacement: 'none',
      renderExpandableRow: (rowData, rowMeta) => renderExpandableRowForWorkstepUsers(rowData, rowMeta),
      customToolbarSelect: () => {
        return isApiCallInprogress ? <CircularProgress size={24} /> : null;
      },
      textLabels: {
        body: {
          noMatch: isApiCallInprogress ? <CircularProgress size={24} /> : "Sorry, no data found",
        },
      },
    };

    return (
      <TableRow>
        <TableCell colSpan={columns.length + 1}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <TableContainer style={{ width: "90%", marginLeft: "10%" }} component={Paper} className="collapse-table-container">
              <Table aria-label="simple table">
                <MUIDataTable
                  className="table_row_workstep"
                  data={workstepTransformedData}
                  columns={workstep_columns}
                  options={workstep_options}
                />
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };

  const renderExpandableRowForWorkstepUsers = (rowData, rowMeta) => {
    const rowId = rowData[0];
    const workflow_steps = tableRows.find(row => row.workflow_step.find(workstep => workstep.id === rowId))?.workflow_step || [];
    const workflow_step = workflow_steps.find(row => row.id === rowId) || {};
    const users = workflow_step.user.sort((a, b) => b.id - a.id) || [];
    return (
      <>
        {
          users.length > 0 && (
            <TableRow>
              <TableCell colSpan={workstep_columns.length + 1}>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <TableContainer style={{ width: "90%", marginLeft: "10%" }} component={Paper} className="collapse-table-container">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="user_id_column">User Id</TableCell>
                          <TableCell>User Name</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>{data.id}</TableCell>
                            <TableCell>{data.user_name}</TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <DeleteIcon onClick={() => confirmDelete(rowId, data.id, users.length)} />{" "}
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Collapse>
              </TableCell>
            </TableRow>
          )
        }
      </>
    )
  }

  const columns = [
    {
      field: "id",
      name: "ID",
      headerAlign: "left",
      width: 10,
      maxWidth: 30
    },
    {
      field: "workflow_name",
      name: "Workflow Name",
      headerAlign: "left",
      width: 150,
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: 'left' }}>{value}</div>
        ),
      },
    }
  ]

  const workstep_columns = [
    {
      field: "id",
      name: "ID",
      headerAlign: "center",
      width: 10,
      maxWidth: 30
    },
    {
      field: "step_name",
      name: "Step Name",
      headerAlign: "left",
      width: 150,
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: 'left' }}>{value}</div>
        ),
      },
    },
    {
      field: "actions",
      type: "actions",
      name: "Actions",
      headerAlign: "left",
      width: 150,
      cellClassName: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowId = tableMeta.rowData[0];
          const workstepName = tableMeta.rowData[1];
          const workflowId = tableRows.find(row => row.workflow_step.find(workstep => workstep.id === rowId))?.id;
          const workflowName = tableRows.find(row => row.workflow_step.find(workstep => workstep.id === rowId))?.workflow_name;
          return (
            <CommonButton
              text="Add User"
              handleClick={() => {
                setSelectedWorkstep({
                  ...selectedWorkstep,
                  selectedWorkflowId: workflowId,
                  selectedWorkstepId: rowId,
                  selectedWorkflowName: workflowName,
                  selectedWorkstepName: workstepName
                })
                setIsOpenAddUserDialog(true)
              }
              }
              hoverColor="#EE3F00"
              className={"add_user_button"}
            />
          )
        }
      }
    },
  ]

  return (
    <>
      <div style={Container}>
        <span
          style={{
            color: "#FF5A01",
            fontSize: "20px",
            fontWeight: "400",
            marginTop: 5,
          }}
        >
          Workflow
        </span>
        <>
          {isApiCallInprogress ? (
            <CircularProgress
              style={{
                position: "absolute",
                top: "70%",
                left: "50%",
              }}
            />
          ) : (
            transformedData && (
              <MUIDataTable
                className="table_row"
                data={transformedData}
                columns={columns}
                options={options}
              />)
          )}
        </>
        <Dialog open={isOpenAddUserDialog} onClose={handleCloseAddUserDialog}>
          <DialogTitle
            id="alert-dialog-title" sx={{ cursor: "default", paddingBottom: "0px" }}
          >
            Add User To Workstep
            <IconButton
              aria-label="close"
              onClick={handleCloseAddUserDialog}
              style={{ position: "absolute", right: 12, top: 12 }}
            >
              <CloseIcon style={CloseIconStyle} />
            </IconButton>
            <hr style={{ width: "100%" }} />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid
                item
                xs={6}
                display={"flex"}
                flexDirection={"column"}
                gap={"5px"}
                mt={"20px"}
              >
                <FormLabel>Workflow Name</FormLabel>
                <TextField
                  value={selectedWorkstep.selectedWorkflowName}
                  disabled={true}
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={6}
                display={"flex"}
                flexDirection={"column"}
                gap={"5px"}
                mt={"20px"}
              >
                <FormLabel>Workstep Name</FormLabel>
                <TextField
                  value={selectedWorkstep.selectedWorkstepName}
                  disabled={true}
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={6}
                display={"flex"}
                flexDirection={"column"}
                gap={"5px"}
                mt={"20px"}
              >
                <FormLabel>Select User</FormLabel>
                <AutoCompleteSelect
                  name={"user"}
                  placeholder="Select User"
                  value={row.user}
                  handleChange={(event, value, reason) => handleUserChange(event, value, reason)}
                  options={activeUsersList || []}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CommonButton
              text="Cancel"
              handleClick={handleCloseAddUserDialog}
              hoverColor="#EE3F00"
            />
            <CommonButton
              text="Submit"
              handleClick={handleAddUser}
              hoverColor="#EE3F00"
              disabled={row.user === ""}
            />
          </DialogActions>
        </Dialog>

        <DialogBox
          title="Confirm Deletion"
          body="Are you sure you want to delete this record?"
          confirmText="Confirm"
          isOpen={open}
          onCancel={handleDeleteUserCancelled}
          onConfirm={handleDeleteUserConfirmed}
        />
      </div>
    </>
  );
}