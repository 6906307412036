import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { inputStyleObj, selectStyleObj } from "./WriteOff";
import { customDateFormat, parseAllocationDate } from "./utils";
import { useRefund } from "./hooks";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UpdateGenericWorkflowDialog from "../Workflow/UpdateGenericWorkflowDialog";
import { numberFormatter } from "../../utils/generalFunctions";

const History = ({ fixedDetails, history }) => {
  const [
    openUpdateGenericRefundWorkflowDialog,
    setOpenUpdateGenericRefundWorkflowDialog,
  ] = useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");
  const [currentTxnWorkflowDetails, setCurrentTxnWorkflowDetails] = useState(
    {}
  );

  const handleUpdateGenericWorkflowDialog = () => {
    setOpenUpdateGenericRefundWorkflowDialog(false);
  };

  return (
    <div className="cash-allocation-dialogue-container">
      <Grid container>
        {fixedDetails.map(({ label, value }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            key={label}
            paddingLeft={i % 2 ? 2 : 0}
            paddingRight={i % 2 ? 0 : 2}
            marginBottom={2}
          >
            <span style={{ fontWeight: label === "Policy#" ? 800 : 400 }}>
              {label}
            </span>
            <span style={{ fontWeight: "bold" }}>: {value}</span>
          </Grid>
        ))}
      </Grid>
      <TableContainer sx={{ height: 275, overflow: "auto" }}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Requested On
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Reason
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Amt
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Ccy
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                WF Status
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {history.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell className="table-cell">
                  {customDateFormat(row?.request_date)}
                </TableCell>
                <TableCell className="table-cell">
                  {row?.refund_reason}
                </TableCell>
                <TableCell className="table-cell">{numberFormatter(row?.refund_amt ?? 0)}</TableCell>
                <TableCell className="table-cell">{row?.currency}</TableCell>
                <TableCell className="table-cell">
                  {row?.workflow?.workflow_status}
                </TableCell>
                <TableCell className="table-cell">
                  <IconButton>
                    <SettingsTwoToneIcon
                      onClick={() => {
                        setCurrentWorkflowName(
                          row?.workflow?.workflow_json_data?.workflow_name ?? ""
                        );
                        setCurrentTxnWorkflowDetails(row?.workflow ?? {});
                        setOpenUpdateGenericRefundWorkflowDialog(true);
                      }}
                      sx={{ fontSize: "25px", color: "#3b3f44" }}
                    />
                  </IconButton>
                  <IconButton>
                    <FileDownloadOutlinedIcon
                      sx={{ fontSize: "25px", color: "blue" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openUpdateGenericRefundWorkflowDialog && (
        <UpdateGenericWorkflowDialog
          openUpdateGenericWorkflowDialog={
            openUpdateGenericRefundWorkflowDialog
          }
          handleUpdateGenericWorkflowDialog={handleUpdateGenericWorkflowDialog}
          workflowName={currentWorkflowName}
          currentWorkflowDetails={currentTxnWorkflowDetails}
        />
      )}
    </div>
  );
};

const Refund = ({ allocation, handleClose, currencies, transaction }) => {
  const { history, formData, setFormData, submit, isApiInProgress,loadHistory } = useRefund(allocation);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showHistory, setShowHistory] = useState(true);

  const fixedDetails = useMemo(
    () => [
      {
        label: "Cash Ref#",
        value: allocation?.cash_reference,
      },
      {
        label: "Txn Date",
        value: customDateFormat(allocation?.bank_txn?.Payment_Receive_Date),
      },
      {
        label: "System",
        value: allocation?.allocation_systemid,
      },
      {
        label: "Bank A/c#",
        value: allocation?.bank_txn?.bank_details?.account_number,
      },
      {
        label: "Status",
        value: allocation?.allocation_status,
      },
      {
        label: "Receivable Amt",
        value: numberFormatter(allocation?.bank_txn?.Receivable_Amount ?? 0),
      },
    ],
    [allocation]
  );

  useEffect(() => {
    setShowHistory(!!history.length);
  }, [history?.length]);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    handleClose("");
  }, [setIsDialogOpen]);

  const handleSubmitAssing = useCallback(
    async (e) => {
      e.preventDefault();
      const submitResponse = await submit();
      if(submitResponse && ("shouldShowHistory" in submitResponse) && submitResponse.shouldShowHistory) {
        setShowHistory(true);
        setFormData("");
        loadHistory();
      }
    },
    [setIsDialogOpen, submit]
  );

  const handleInputChange = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  const refundTypes = [
    { label: "Refund" },
    { label: "Returned Premium" },
    { label: "Profit Commision" },
    { label: "No Claim Bonus" },
    { label: "Broker Commision" },
    { label: "Rebates" },
    { label: "Fees" },
  ];
  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
      <form onSubmit={handleSubmitAssing}>
        <DialogTitle
          style={{
            background: "#FCFCFC",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Typography sx={{ fontSize: 20 }}>Refund</Typography>
          <Typography
            sx={{ fontSize: 15, fontWeight: 750, marginLeft: "30px" }}
          >
            Policy#: {allocation?.policy_id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {showHistory ? (
            <History
              setShowHistory={setShowHistory}
              fixedDetails={fixedDetails}
              history={history}
            />
          ) : (
            <>
              <Grid container>
                {fixedDetails.map(({ label, value }, i) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={label}
                    paddingLeft={i % 2 ? 2 : 0}
                    paddingRight={i % 2 ? 0 : 2}
                    marginBottom={2}
                  >
                    <span>{label} </span>
                    <span style={{ fontWeight: "bold" }}>: {value}</span>
                  </Grid>
                ))}
              </Grid>

              <Grid container>
                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Refund Type</p>
                  <Autocomplete
                    name="RefundType"
                    size="small"
                    autoHighlight
                    options={refundTypes}
                    renderInput={(params) => (
                      <TextField
                        placeholder={"Select Refund Type"}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Refund Reason
                  </p>

                  <Input
                    style={{ ...inputStyleObj }}
                    placeholder="Write details here"
                    name="refund_reason"
                    value={formData.refund_reason}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Amount</p>

                  <Input
                    style={{ ...inputStyleObj }}
                    placeholder="Enter amount here"
                    name="refund_amt"
                    value={formData.refund_amt}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.target.value = formData.refund_amt || "";
                        return;
                      }
                      handleInputChange(e);
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Currency</p>

                  <Select
                    style={{ ...selectStyleObj }}
                    name="currency"
                    onChange={handleInputChange}
                    value={formData?.currency ?? allocation?.payment_currency}
                    placeholder="Write details here"
                    required
                  >
                    {currencies.map((opt) => (
                      <MenuItem value={opt.currency_code}>
                        {opt.currency_code}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Comments</p>

                  <TextareaAutosize
                    style={{
                      height: 50,
                      width: "100%",
                    }}
                    name="comments"
                    value={formData.comments}
                    onChange={handleInputChange}
                    placeholder="Write details here"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => setShowHistory(!showHistory)}
            color={showHistory ? "warning" : "inherit"}
            variant={showHistory ? "contained" : "outlined"}
            sx={{ borderRadius: 100 }}
          >
            {showHistory ? "Add" : "History"}
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="inherit"
            variant="outlined"
            sx={{ borderRadius: 100 }}
          >
            Close
          </Button>
          {!showHistory && (
            <Button
              variant="contained"
              color="warning"
              sx={{ borderRadius: 100 }}
              type="submit"
              disabled={isApiInProgress}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Refund;
