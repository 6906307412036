import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
  } from "@mui/x-data-grid";
  import React, { useEffect, useState } from "react";
  import { useSelector } from "react-redux";
  import { getFIles, GetUsers } from "../../Services/api";
  import instance from "../../redux/api";
  import { CommonButton } from "../common/commonButton";
  import format from 'date-fns/format';
  import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    IconButton,
    Stack,
    TableContainer,
    TextField,
    Tooltip,
  } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { CommonSelect, CommonPaginationContainer } from "../common";
import { getPaginationCount } from "../CashAllocation/utils";  
import CustomDateRangePicker from "../common/CustomDateRangePicker";
  //styles
  
  const AssignBankStatementBar = {
    display: "flex",
    justifyContent: "space-between",
    height: 32,
    fontWeight: "400",
    marginTop: 10,
  };
  const editEntityUpdate = {
    width: "90px",
    padding: "18px 0px",
    borderRadius: "20px",
    gap: "8px",
  };
  
  const bankTransactionAddBtn = {
    background: "#FF5A01",
    marginLeft: "5%",
    color: "white",
    borderRadius: "10px",
    padding: "5px 20px",
  };
  
  const bankStatementSearchBar = {
    width: "230px",
    height: "30px",
    padding: "2px 5px 1px 15px",
    borderRadius: "7px",
    border: "1px solid #d2cccc",
    marginTop: "5px",
  };
  
  const dialogStyle = {
    maxWidth: "90vw",
    width: "90vw",
    margin: "auto",
  };
  const ResetSubmit = {
    justifyContent: "flex-end",
    paddingTop: "0px",
    marginRight: "12px",
  };
  const addBankTransactionReset = {
    backgroundColor: "white",
    width: "90px",
    padding: "16px 0px",
    borderRadius: "20px",
    gap: "8px",
    color: "black",
    border: "1px solid black",
  };
  
  const EditPageContainerTransactions = {
    padding: "10px",
    cursor: "default",
    width: "100%",
    marginLeft: "-10px",
  };
  const EditPageContainerTransactionsList = {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
  };
  
  const CloseIconStyle = {
    backgroundColor: "#3B3F44",
    color: "#ffff",
    height: "16px",
    width: "16px",
    padding: "7px 8px",
    borderRadius: "32px",
  };
  const addBankTransactionSubmit = {
    backgroundColor: "#FF5A01",
    width: "90px",
    padding: "17px 0px",
    borderRadius: "13px",
    gap: "8px",
    color: "white",
  };
  
  export default function FileManagement() {
    // Selectors
    const toggle = useSelector((state) => state.toggleSideMenu);
    const { userData } = useSelector((state) => state?.user);
    
    const Container = {
        width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
        display: "flex",
        flexDirection: "column",
        gap: 8,
      };
      

    const [tableRows, setTableRows] = useState([]);
    const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [fromDateValue, setFromDateValue] = useState("");
    const [toDateValue, setToDateValue] = useState("");
  
    const classes = useStyles();
    const [searchFieldsPrams, setSearchFieldsPrams] = useState({
      File_Name: "",
      Module_Name: "",
      Uploaded_At: "",
      Uploaded_By: null,
    });
  
    //For pagination
    const [paginationCount, setPaginationCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [pageState, setPageState] = useState({
      isLoading: true,
      data: [],
      total: 0,
      page: 1,
      pageSize: 25,
    });
  
    // skip 0 means page 1
    const currentSkipNumber = () => {
      return pageState?.page === 1
        ? 0
        : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
    };
  
    useEffect(() => {
      setPaginationCount(
        getPaginationCount(pageState?.total, pageState?.pageSize),
      );
    }, [pageState?.pageSize, pageState?.total]);

    useEffect(() => {
      loadData();
    }, [pageState?.pageSize, pageState?.page]);

    const handleFieldsInputChange = (name, value) => {
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        [name]: value ?? "",
      });
    };

    const handleSearchFiles = async () => {
      try {
        let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;
  
        const { File_Name, Module_Name, Uploaded_At, Uploaded_By } = searchFieldsPrams;
        if (File_Name) {
          queryParams = `${queryParams}&file_name=${File_Name}`;
        }
  
        if (Module_Name) {
          queryParams = `${queryParams}&module_name=${Module_Name}`;
        }
  
        if (Uploaded_At) {
          queryParams = `${queryParams}&uploaded_at=${Uploaded_At}`;
        }
  
        if (fromDateValue) {
          const formattedFromDateValue = format(fromDateValue, "yyyy-MM-dd");
          queryParams = `${queryParams}&from_date=${formattedFromDateValue}`;
        }
  
        if (toDateValue) {
          const formattedToDateValue = format(toDateValue, "yyyy-MM-dd");
          queryParams = `${queryParams}&to_date=${formattedToDateValue}`;
        }
  
        if (Uploaded_By) {
          queryParams = `${queryParams}&uploaded_by=${Uploaded_By.id}`;
        }
  
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));
  
        const response = await instance.get(`${getFIles}${queryParams}`);
  
        setPageState((old) => ({
          ...old,
          isLoading: false,
        }));
  
        if (response.status === 200) {
          console.log("broker", response);
          setPageState((old) => ({
            ...old,
            total: response?.data?.count,
            data: response?.data?.data ?? [],
          }));
          setPaginationCount(
            Math.floor(response?.data?.count / pageState?.pageSize)
          );
        } else {
          setPageState((old) => ({
            ...old,
            total: response?.data?.count ?? 0,
            data: [],
          }));
          setPaginationCount(0);
        }
      } catch (err) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));
        console.log("err", err);
      }
    };

    const handleClearSearchList = () => {
      loadData();
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        File_Name: "",
        Module_Name: "",
        Uploaded_By: null,
      });
      setFromDateValue("");
      setToDateValue("");
    };

    useEffect(() => {
      loadUserData()
    }, [])

    const loadUserData = async () => {
      try {
        // setUsers
        await instance.get(`${GetUsers}`).then((response) => setUsers(response.data))
        .catch((error) => console.error("Error : ", error));
      } catch (err) {
        console.error("Error : ", err)
      }
    }
  
    const loadData = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));
        const response = await instance.get(
          `${getFIles}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`
        );
        setPageState((old) => ({
          ...old,
          isLoading: false,
        }));
  
        if (response.status === 200) {
          setPageState((old) => ({
            ...old,
            total: response?.data?.count,
            data: response?.data?.data ?? [],
          }));
        } else {
          setPageState((old) => ({
            ...old,
            total: response?.data?.count,
            data: [],
          }));
          setPaginationCount(0);
        }
      } catch (err) {
        setPageState((old) => ({
          ...old,
          isLoading: false,
        }));
        setPaginationCount(0);
        console.error("err", err);
      }
    };
  
    
    const columns = [
      {
        field: "file_name",
        headerName: "File Name",
        // width: 300,
        flex: 3,
        headerAlign: "center",
      },
      {
        field: "module_name",
        headerName: "Module Name",
        // width: 150,
        flex: 1.5,
        headerAlign: "center",
      },
      {
        field: "file_info",
        headerName: "#Records",
        // width: 80,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => {
          const data = JSON.parse(params.row.file_info);
          return data.record_count;
        }
      },
      {
        field: "file_type",
        headerName: "File Type",
        // width: 80,
        flex: 0.8,
        headerAlign: "center",
      },
      {
        field: "file_size",
        headerName: "File Size",
        // width: 80,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => (
          <span>{formatFileSize(params?.row?.file_size)}</span>
          ),
      },
      {
        field: "created_by",
        headerName: "Uploaded By",
        // width: 120,
        flex: 1.2,
        headerAlign: "center",
        renderCell: (params) => (
        <span>{params?.row?.created_by?.user_name || ''}</span>
        ),
      },
      {
        field: "created_at",
        headerName: "Uploaded At",
        // width: 120,
        flex: 1.2,
        headerAlign: "center",
        renderCell: (params) => (
            <span>{format(new Date(params.row?.created_at), "dd-MM-yyyy")}</span>
        ),
      },
      {
        field: "action",
        headerName: "Action",
        // width: 120,
        flex: 1.2,
        headerAlign: "center",
        renderCell: (params) => (
            <a
                href={params.row.file_path || "#"}  // Set the file path as the href
                download={params.row.file_path}  // The filename will be extracted from the URL and used as the download name
            >
                <DownloadIcon
                    style={{ marginRight: 8, cursor: "pointer", color:"#FF5A01" }}
                    titleAccess="Download File"
                    color="green"
                />
            </a>
        ),
      }
    ];

    const formatFileSize = (bytes) => {
      if(!bytes) return "-";
      if (bytes === 0) return '0 Byte';
      
      const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(1024)); 
      const fileSize = (bytes / Math.pow(1024, i)).toFixed(2);
      
      return `${fileSize} ${sizes[i]}`;
    }

    const isFilterBtnDisable = () => {
      let disabled = true;
      const { File_Name, Module_Name, Uploaded_By } = searchFieldsPrams;
      if (File_Name !== "" || Module_Name !== "" || Uploaded_By || fromDateValue !== "" || toDateValue !== "") {
        disabled = false;
      }
      return disabled;
    };

    // Handle input change for the search field
    const handleInputChange = (value) => {
        setSearchText(value);
    };

    // Handle change when a user is selected
    // const handleChange = (event, value) => {
    //     setSelectedUser (value);
    // };

    // Filter users based on the search text
    useEffect(() => {
      setFilteredUsers(users.filter(user =>
          user.user_name.toLowerCase().includes(searchText.toLowerCase())
      ));
    }, [searchText, users]);
    
    
    return (
      <div style={Container}>
        <div style={AssignBankStatementBar}>
          <span style={{ color: "#FF5A01", fontSize: "24px" }}>
            Document Repository
          </span>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSearchFiles();
        }}>
        <Grid container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: "10px",
          }}
          xs={12}
          gap={"20px"}
        >
          <Grid item xs={2.5} display={"flex"} flexDirection={"column"} gap={"6px"}>
            <FormLabel>File Name</FormLabel>
            <TextField
              value={searchFieldsPrams?.File_Name}
              onChange={(e) =>
                handleFieldsInputChange("File_Name", e.target.value)
              }
              size="small"
              placeholder="Enter File Name Here"
            />
          </Grid>
          <Grid item xs={2.5} display={"flex"} flexDirection={"column"} gap={"6px"}>
            <FormLabel>Module Name</FormLabel>
              <TextField
                value={searchFieldsPrams?.Module_Name}
                onChange={(e) =>
                  handleFieldsInputChange("Module_Name", e.target.value)
                }
                size="small"
                placeholder="Enter module name Here"
              />
          </Grid>
          <Grid item xs={2.5} display={"flex"} flexDirection={"column"} gap={"6px"}>
            <FormLabel>Uploaded By</FormLabel>
              {/* <TextField
                value={searchFieldsPrams?.Uploaded_By}
                onChange={(e) =>
                  handleFieldsInputChange("Uploaded_By", e.target.value)
                }
                size="small"
                placeholder="Enter user name here"
              /> */}
              {/* <CommonSelect
                placeholder="Select user name"
                value={users.find(option => option.id === searchFieldsPrams?.Uploaded_By) || null}
                handleChange={(event, value, reason) => handleFieldsInputChange("Uploaded_By", value.id) }
                options={users}
                getOptionLabel={(option) => option.user_name ?? option} // Use 'name' as the label for each option
                getOptionSelected={(option, value) => option.id === value.id} // Compare 'value' of option and selected value
                isOptionEqualToValue={(option, value) => option.id === value.id} // Ensure matching 'value'
                customStyles={{ width: "230px" }}
              /> */}

              <CommonSelect
                options={filteredUsers}
                value={filteredUsers.find(option => option.id === searchFieldsPrams?.Uploaded_By) || null}
                // value={searchFieldsPrams?.Uploaded_By }
                handleChange={(event, value, reason) => handleFieldsInputChange("Uploaded_By", value )}
                handleInputChage={handleInputChange}
                placeholder="Select user name"
                getOptionLabel={(option) => option.user_name ?? option} // Use 'name' as the label for each option
                customStyles={{ minWidth: 200 }}
                // You can add other props as needed
            />
          </Grid>
          <Grid item xs={2.5} display={"flex"} flexDirection={"column"} gap={"6px"}>
            <FormLabel>Uploaded At</FormLabel>
              <CustomDateRangePicker
                startDate={fromDateValue}
                setStartDate={setFromDateValue}
                endDate={toDateValue}
                setEndDate={setToDateValue}
                maxDate={new Date()}
                handleSubmit={(event) => event.preventDefault()}
              />
          </Grid>
          <Grid item display={"flex"} gap={"10px"} mt={"22px"} >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              handleClick={handleSearchFiles}
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
              type="submit"
            />
            <FormLabel></FormLabel>
            <CommonButton
              text="Reset"
              handleClick={handleClearSearchList}
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading}
              style={{ color: isFilterBtnDisable() ?? "rgba(0, 0, 0, 0.26)" }}
            />
          </Grid>
        </Grid>
        </form>
        <div
          style={{
            height: "calc(100vh - 240px)",
            position: "relative",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          {/* <DataGrid
            className={classes.pagination}
            loading={pageState?.isLoading}
            rows={pageState?.data ?? []}
            columns={columns}
            rowCount={pageState?.total}
            keepNonExistentRowsSelected
            disableSelectionOnClick
            pagination
            paginationMode="server"
            // getRowId={(row) => row?.id}
            getRowId={(row) => pageState?.data.indexOf(row)}
            page={pageState.page - 1}
            pageSize={pageState?.pageSize ?? 10}
            rowsPerPageOptions={[2, 10, 25, 50, 100]}
            
            onPageSizeChange={(newPageSize) => {
              // return setPageState((old) => {
              //   console.log("setPaginationCount 5 : ", pageState)
              //   setPaginationCount(Math.floor(pageState.total / newPageSize));
              //   return { ...old, pageSize: newPageSize, page: 1 };
              // });
              return setPageState((old) => {
                setPaginationCount(
                  getPaginationCount(pageState?.total, newPageSize),
                );
                return { ...old, pageSize: newPageSize, page: 1 };
              });
            }}
            components={{
              Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No files found
                </Stack>
              ),
            }}
            sx={{
              "& .MuiDataGrid-columnHeader[data-field='actions']": {
                minWidth: toggle?.isOpen
                  ? "106px !important"
                  : "123px !important",
              },
            }}
          />
           <Stack
            style={{
              position: "absolute",
              bottom: "8px",
            }}
          >
            <CommonPaginationContainer
              count={paginationCount + 1}
              pageState={pageState}
              setPageState={setPageState}
            />
          </Stack> */}
          <DataGrid
            className={classes.pagination}
            loading={pageState?.isLoading}
            rows={pageState?.data ?? []}
            getRowId={(row) => pageState?.data.indexOf(row)}
            rowCount={pageState?.total}
            columns={columns}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            pagination
            paginationMode="server"
            disableSelectionOnClick
            page={pageState.page - 1}
            pageSize={pageState?.pageSize ?? 25}
            onPageSizeChange={(newPageSize) => {
              return setPageState((old) => {
                setPaginationCount(
                  getPaginationCount(pageState?.total, newPageSize),
                );
                return { ...old, pageSize: newPageSize, page: 1 };
              });
            }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            sx={{
              "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
                minWidth: toggle?.isOpen
                  ? "170.137px !important"
                  : "198px !important",
              },
            }}
            components={{
              Toolbar: GridToolbarContainer,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No Uploaded Files
                </Stack>
              ),
            }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
        </div>
      </div>
    );
  }
  