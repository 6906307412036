import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BankExchangeRateAPI } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Stack,
  TableContainer,
  TextField,
  Select,
  MenuItem,
  ListItemText
} from "@mui/material";
import { ViewAndEditEntity } from "./ViewAndEditEntity";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
// import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MyComponent from "../EntityTableMaintainance/EntityDetails";
import { AddBankExchange } from "./AddBankExchange";
import format from "date-fns/format";
import CommonFileUpload from "../documents/CommonFileUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { getCurrencyList } from "../../redux/slice/bankStatmentTransactionsSlice";
// import { numberFormatter } from "../../utils/generalFunctions";

export const numberFormatter = (value) =>
  new Intl.NumberFormat("en-US", {
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 7, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 7, // (causes 2500.99 to be printed as $2,501)
  }).format(value);

//styles

const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};
const editEntityUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
}

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const bankStatementSearchBar = {
  width: "200px",
  height: "30px",
  padding: "2px 5px 1px 15px",
  borderRadius: "7px",
  border: "1px solid #d2cccc",
  marginTop: "5px"
};

const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};
const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};
const EditPageContainerTransactionsList = {
  display: "flex",
  justifyContent: "end",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px"
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};
const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};

const months = { "January": "01", "February": "02", "March": "03", "April": "04", "May": "05", "June": "06", "July": "07", "August": "08", "September": "09", "October": "10", "November": "11", "December": "12" }

export default function BankExchangeRate() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const dropdownValuesCurreny = useSelector(
    (state) => state?.bankStatmentTransactions?.currencyList
  );

  //Local state
  const [rows, setRows] = useState([{ month: "", currency_code: "", exchange_rate: "" }]);
  // const [tableRows, setTableRows] = useState([]);
  // const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [EntityDialog, setEntityDialog] = useState(false);
  const [editRow, setEditRow] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [editId, setEditId] = useState("");
  // const [pageSize, setPageSize] = useState(5);
  // const [page, setPage] = useState(0);
  const [currentUpdatedFiledsInEditMode, setCurrentUpdatedFiledsInEditMode] =
    useState({});
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const classes = useStyles();
  const [searchFieldPrams, setSearchFieldPrams] = useState({
    Currency_Code: "",
    Transaction_Month: "",
    Transaction_Year: ""
  })
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch Currency Details
    dispatch(getCurrencyList());
  }, []);

   //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  useEffect(() => {
    if (pageState?.total >= 5) {
      setPaginationCount(Math.floor(pageState.total / pageState?.pageSize));
    } else setPaginationCount(0);
  }, [pageState.total]);

  useEffect(() => {
    loadData();
  }, [pageState?.pageSize, pageState?.page]);

  useEffect(() => {
    if (selectAll) {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.fill(selectAll);
      setSelectedRows(updatedSelectedRows);
    }
  }, [selectAll]);

  useEffect(() => {
    const selectedRowCount = selectedRows.filter(Boolean).length;
    if (selectedRowCount < rows.length) {
      setSelectAll(false);
    }
  }, [rows, selectedRows]);
  
  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const response = await instance.get(
        `${BankExchangeRateAPI}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
        }`
      );
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setEntityDialog(false);
    setEditMode(false)
    setViewMode(false)
    if (!editMode && !viewMode) {
      setRows([
        { 
          month: "", 
          currency_code: "", 
          exchange_rate: "" 
        }
      ]);
      setSelectedRows([false]);
      setValidationStatus([true]);
    }
    else if(editMode){
      setValidationStatus([true]);
      setCurrentUpdatedFiledsInEditMode({});
    }
    
  };


  const handleCurrencyDropDown = (name, value, reason) => {
    setSearchFieldPrams({
      ...searchFieldPrams,
      [name]: value ?? ""
    })
    if (reason === "clear") {
      loadData();
      setSearchFieldPrams({
        ...searchFieldPrams,
        Transaction_Month: "",
        Currency_Code: "",
        Transaction_Year: ""
      })
    }
  }


  const handleTextFieldchange = (name, value) => {
    setSearchFieldPrams({
      ...searchFieldPrams,
      [name]: value ?? ""
    });
  };


  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Currency_Code, Transaction_Month, Transaction_Year } = searchFieldPrams;
    if (Currency_Code !== "" || Transaction_Month !== "" || Transaction_Year !== "") {
      disabled = false;
    }
    return disabled;
  }


  const handleSearchBnkExchangeList = async () => {
    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const { Currency_Code, Transaction_Month, Transaction_Year } = searchFieldPrams;
      if (Currency_Code) {
        queryParams = `${queryParams}&currencyCode=${Currency_Code}`;
      }

      // let formattedFromDateValue = "";

      if (Transaction_Year) {
        queryParams = `${queryParams}&transactionYear=${Transaction_Year}`;
    }
      if (Transaction_Month) {
        // formattedFromDateValue = format(Transaction_Month, "dd/MM/yyyy");
        // queryParams = `${queryParams}&dateReceived=${formattedFromDateValue}`;
        queryParams = `${queryParams}&transactionMonth=${Transaction_Month}`
      }

      setPageState((old) => ({
        ...old,
        isLoading: true
      }));

      const response = await instance.get(`${BankExchangeRateAPI}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        console.log("broker", response)
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data ? response?.data : [],
        }));
        setPaginationCount(Math.floor(response?.data?.count / pageState?.pageSize));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
        }));
        setPaginationCount(0);
      }

    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true
      }));
      console.log("err", err);
    }
  };


  const handleClearSearchList = () => {
    loadData();
    setSearchFieldPrams({
      ...searchFieldPrams,
      Currency_Code: "",
      Transaction_Month: "",
      Transaction_Year: ""
    })
  }



  // const handleSearch = (e) => {
  //   const value = e.target.value;
  //   setSearchText(value);
  //   let filteredData;
  //   if (value.trim() === '') {
  //     loadData();
  //     filteredData = tableRows;
  //   } else {
  //     filteredData = tableRows.filter((row) =>
  //       columns.some((column) => {
  //         const cellValue = row[column.field];
  //         if (Array.isArray(cellValue)) {
  //           // Check if the array is not null
  //           if (cellValue !== null && cellValue !== undefined) {
  //             // Check if any property within the objects in the array includes the search value
  //             return cellValue.some((item) =>
  //               Object.values(item).some(
  //                 (propValue) =>
  //                   propValue !== undefined &&
  //                   propValue !== null &&
  //                   propValue.toString().includes(value)
  //               )
  //             );
  //           }
  //         } else if (cellValue !== undefined && cellValue !== null) {
  //           // For non-array fields, continue with the existing logic
  //           return cellValue.toString().includes(value);
  //         }
  //         return false;
  //       })
  //     );
  //   }
  //   setTableRows(filteredData);
  // };

  // const handleFileUploadDialog = () => {
  //   setIsFileUploadDialogOpen(true);
  // };
  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;

    // Check the number of selected rows
    const selectedRowCount = updatedSelectedRows.filter(Boolean).length;

    setSelectedRows(updatedSelectedRows);
    setSelectAll(selectedRowCount === rows.length);
  };

  const handleActionButton = (rowData, isEditMode) => {
    const emptyRows = Array.from({ length: rows.length }, () => ({ month: "", currency_code: "", exchange_rate: "" }));
    if (rowData && isEditMode) {
      // Pre-fill the empty row with data for editing
      setRows([{...rowData}]);
      setEditRow(rowData);
      setEditMode(true);
      setEditId(rowData.id);
      setIsCreated(false);
    } else if (rowData && !isEditMode) {
      setRows([rowData]);
      setViewMode(true);
    } else {
      // If no rowDataForEdit is provided, it means we are in add mode
      setRows(emptyRows);
      setIsCreated(true);
      setEntityDialog(true);
    }
    setEntityDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3, headerAlign: "center" },
    {
      field: "year",
      headerName: "Transaction Year",
      flex: 0.8,
      headerAlign: "center",
      valueGetter: (params) => {
          // Check if params.value is a valid date
          const dateValue = new Date(params?.row?.month);
          if (!isNaN(dateValue.getTime())) {
              // If it's a valid date, format it
              return format(dateValue, "yyyy");
          } else {
              // If it's not a valid date, return null
              return "";
          }
      },
    },
    {
      field: "month",
      headerName: "Transaction Month",
      flex: 0.8,
      headerAlign: "center",
      valueGetter: (params) => {
        // Check if params.value is a valid date
        const dateValue = new Date(params.value);
        if (!isNaN(dateValue.getTime())) {
          // If it's a valid date, format it
          return format(dateValue, "MMMM");
        } else {
          // If it's not a valid date, return null
          return "";
        }
      },
    },
    {
      field: "currency_code",
      headerName: "Currency Code",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "exchange_rate",
      headerName: "Exchange Rate",
      flex: 1,
      headerAlign: "center",
      renderCell:(params)=><>{numberFormatter(params?.row?.exchange_rate)??""}</>, 
      cellClassName:"amount-table-cell"
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      headerAlign: "center",
      valueGetter:(params)=>params?.value??"-"
    },
    // {
    //   field: "updated_by",
    //   headerName: "Last Modified By",
    //   flex: 1,
    //   headerAlign: "center",
    //   valueGetter:(params)=>params?.value??"-"
    // },
    ...(userData?.role !== "CC Processor" ? [{
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.8,
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];

        // if (UserAuthorisation?.['Edit'] === "Y") {
        // actionsList.push(
        //   <GridActionsCellItem
        //     icon={
        //       <Tooltip title="Edit">
        //         {" "}
        //         <EditIcon />{" "}
        //       </Tooltip>
        //     }
        //     label="Edit"
        //     className="textPrimary"
        //     onClick={() => handleActionButton(row, true)}
        //     color="inherit"
        //   />
        // );
        // }

        // if (bankTransactions?.['Delete'] === "Y") {
        //   actionsList.push(
        //     <GridActionsCellItem
        //       icon={<Tooltip title="Delete"><DeleteIcon /> </Tooltip>}
        //       label="Delete"
        //       onClick={() => handleDeleteClick(row.id)}
        //       color="inherit"
        //     />
        //   );
        // }

        if (UserAuthorisation?.['View'] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="view"
              className="textPrimary"
              onClick={() => handleActionButton(row, false)}
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    }] : [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 0.8,
        cellClassName: "actions",
        getActions: ({ row }) => {
          let actionsList = [];
  
          // if (UserAuthorisation?.['View'] === "Y") {
            actionsList.push(
              <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="view"
                className="textPrimary"
                onClick={() => handleActionButton(row, false)}
                color="inherit"
              />
            );
          // }
  
          return actionsList;
        },
      }
    ]),
  ];
  // const loadData = async () => {
  //   try {
  //     setIsApiCallInprogress(true);
  //     const resp = await instance.get(BankExchangeRateAPI);
  //     setIsApiCallInprogress(false);
  //     if (resp.status === 200) {
  //       const sortedData = resp.data.sort((a, b) => b.id - a.id);
  //       setTableRows(sortedData);
  //     } else {
  //       setTableRows([]);
  //     }
  //   } catch (err) {
  //     setIsApiCallInprogress(false);
  //     console.error("err", err);
  //   }
  // };
  const UserAuthorisation =
    userData?.user_permissions?.permissions_list['Admin Table Maintenance'];

  // useEffect(() => {
  //   loadData();
  // }, []);

  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      if(colName === "exchange_rate") {
        updatedRows[index][colName] = Number(value);
      } else {
        updatedRows[index][colName] = value;
      }
      setRows(updatedRows);
      // Edit Mode
      // if (editMode) {
      //   setCurrentUpdatedFiledsInEditMode({
      //     ...currentUpdatedFiledsInEditMode,
      //     [colName]: value,
      //   });
      // }
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  const handleAddRow = () => {
    setRows([...rows, { month: "", currency_code: "", exchange_rate: "" }]);
  };
  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    if (rows.length <= 1 || selectAll) {
      // Throw an error or show a message indicating that at least one row should remain
      toast.error("At least One Row Should Remain!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows([]);
    setSelectAll(false);
  };

  const validateRow = (index) => {
    const row = rows[index];
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (!row || !row.month || row.month.trim() === "") {
      fieldValidations.month = "This field is required";
    }
    if (!row || !row.currency_code || row.currency_code.trim() === "") {
      fieldValidations.currency_code = "This field is required";
    }
    if (!row || !row.exchange_rate || !row.exchange_rate) {
      fieldValidations.exchange_rate = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };
  
  const handleSubmit = async () => {
    // Validate each row before submission
    const areRowsValid =  rows
    .map((_, index) => validateRow(index))
    .every((value) => value);

    let editModeObj = {};
    if (!editMode) {
      // Add Mode
      // editModeObj.id = editRow?.id;
      // editModeObj["created_by"] = userData?.user_name ?? "";
      // editModeObj = { ...editModeObj, ...currentUpdatedFiledsInEditMode };
    } else {
      editModeObj.id = editRow?.id;
      editModeObj["updated_by"] = userData?.user_name ?? "";
      editModeObj = { ...editModeObj, ...currentUpdatedFiledsInEditMode };
    }

    if (!areRowsValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fix validation errors before submitting.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
      return;
    }

    try {
      let response;
      if (editMode) {
        response = await instance.patch(
          BankExchangeRateAPI + editId + "/",
          editModeObj
        );
        // clear preiously updated stored data
        setCurrentUpdatedFiledsInEditMode({});
      } else {
        let updatedRows = [...rows];
        for (let i = 0; i < updatedRows?.length; i++) {
          updatedRows[i].created_by = userData?.user_name;
        }
        response = await instance.post(BankExchangeRateAPI, updatedRows);
      }

      if (response) {
        if (editMode) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };

  const handleReset = () => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      month: "", 
      currency_code: "", 
      exchange_rate: "" 
    }));
    setRows(emptyRows);
    setSelectAll(false);
    setValidationStatus(Array.from({ length: rows.length }, () => true));
    setSelectedRows(selectedRows.fill(false));
  };

  // const uploadFile = async (formData) => {
  //   try {
  //     const response = await instance.post(
  //       "documents/roe_file_upload/",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );  
  //     if (response.status === 200) {

  //       toast.success("File uploaded successfully.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 1000,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error(error?.data?.message || "File not uploaded!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //   }
  //   setIsButtonDisabled(false);
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;

  //   setIsButtonDisabled(true);

  //   const fileSizeLimit = 10485760;
  
  //   // Check if the file is an Excel file
  //   const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', "text/csv"];
  //   if (!validTypes.includes(file.type)) {
  //     toast.error("Please upload a valid Excel file.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //     setIsButtonDisabled(false);
  //     return;
  //   }

  //   // Check if the file size exceeds the limit
  //   if (file.size > fileSizeLimit) {
  //     toast.error("File size should be less than or equal to 10 MB.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //     setIsButtonDisabled(false);
  //     return;
  //   }

  //   // Check if the file name length exceeds 256 characters
  //   if (file.name.length > 256) {
  //     toast.error("File name should be less than 256 characters.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //     setIsButtonDisabled(false);
  //     return;
  //   }
  
  //   // Prepare FormData
  //   const formData = new FormData();
  //   formData.append("file", file);
  
  //   // You can add additional parameters here if needed
  //   // formData.append("paramName", paramValue);
  
  //   uploadFile(formData);
  // };

  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Exchange Rate
        </span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <div className="add-upload-container" style={{display:"flex", gap:"10px"}}>
              <CommonButton
                text="Add"
                handleClick={() => handleActionButton()}
                sx={bankTransactionAddBtn}
                hoverColor="#FF5A01"
              />
              {/* <Button
                color="inherit"
                variant="outlined"
                component="label"
                style={{ width: "150px", height:"33px" }}
                disabled={isButtonDisabled}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  multiple
                  name="file"
                  accept=".doc,.docx,.xlsx,.csv,.pdf"
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                  required
                  style={{ borderBottom: "none" }}
                />
              </Button> */}
            </div>
          )}
        </div>
      </div>

      <Grid container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "10px",
        }}
        xs={12}
        gap={"10px"}
      >
        <Grid item xs={2.5} gap={"4px"} >
          <FormLabel>Currency Code</FormLabel>
          <CommonSelect
            handleChange={(event, value, reason) =>
              handleCurrencyDropDown(
                // index,
                "Currency_Code",
                value,
                reason,
                event
              )
            }
            // Map through your list of dropdown values
            options={dropdownValuesCurreny?.map(
              (option) => option?.currency_code
            )}
            placeholder="Select Currency"
            value={searchFieldPrams?.Currency_Code}
          />
          {/* <input
            style={bankStatementSearchBar}
            type="text"
            placeholder="Search..."
            value={searchText}
          // onChange={handleSearch}
          /> */}
         </Grid>
          <Grid item xs={2} gap={"4px"}>
              <FormLabel>Transaction Year</FormLabel>
              <TextField
                  value={searchFieldPrams?.Transaction_Year}
                  onChange={(e) =>
                      handleTextFieldchange(
                          "Transaction_Year",
                          e.target.value
                      )
                  }
                  // onKeyUp={(e) => handleEnter(e)}
                  size="small"
              />
          </Grid>
          <Grid item xs={2} gap={"4px"} display={"flex"} flexDirection={"column"}>
            <FormLabel>Transaction Month</FormLabel>
            <Select
                value={searchFieldPrams?.Transaction_Month}
                onChange={(e) =>
                    handleTextFieldchange(
                        "Transaction_Month",
                        e.target.value
                    )
                }
                // onKeyUp={(e) => handleEnter(e)}
                size="small"
                sx={{height:"37px"}}
            >
                {
                    Object.entries(months).map(([key, value], index) => (
                        <MenuItem value={value}>
                            <ListItemText
                                primary={key}
                                className="list-item"
                            />
                        </MenuItem>
                    ))
                }
            </Select>
        </Grid>
        {/* <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={3}
        >
          <FormLabel>Transaction Month</FormLabel>
          <Input
            type="date"
            value={searchFieldPrams?.Transaction_Month}
            onChange={(e) =>
              handleTextFieldchange("Transaction_Month", e.target.value)
            }
            className={classes.transactionReceivedDate}
          />
        </Grid> */}
        <Grid item display={"flex"} gap={"10px"} mt={"22px"} xs={3}>
          <FormLabel></FormLabel>
          <CommonButton
            text="Search"
            handleClick={handleSearchBnkExchangeList}
            hoverColor="#EE3F00"
            disabled={pageState?.isLoading || isFilterBtnDisable()}
          />
          <FormLabel></FormLabel>
          <CommonButton
            text="Clear"
            handleClick={handleClearSearchList}
            hoverColor="#EE3F00"
            disabled={pageState?.isLoading || isFilterBtnDisable()}
            style={{ color: isFilterBtnDisable() ?? "rgba(0, 0, 0, 0.26)" }}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            onClick={handleFileUploadDialog}
          >
            <span
              style={{ display: "flex", justifyContent: "center" }}
            >
              Files: <AttachFileIcon />
            </span>
            {uploadedFiles[0]?.name}
          </div>
        </Grid> */}
        {/* <CommonFileUpload
          isFileUploadDialogOpen={isFileUploadDialogOpen}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          setIsFileUploadDialogOpen={setIsFileUploadDialogOpen}
        /> */}
      </Grid>

      <div
        style={{
          height: 'calc(100vh - 230px)',
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          hideFooterPagination
          hideFooter
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          // page={pageState.page - 1}
          // pageSize={pageState?.pageSize ?? 10}
          // rowsPerPageOptions={[10, 25, 50, 100]}
          // onPageSizeChange={(newPageSize) => {
          //   return setPageState((old) => {
          //     setPaginationCount(Math.floor(pageState.total / newPageSize));
          //     return { ...old, pageSize: newPageSize, page: 1 };
          //   });
          // }}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Exchange Rate
              </Stack>
            ),
          }}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='actions']": {
              minWidth: toggle?.isOpen ? "260px !important" : "303px !important"
            }
          }}
        // loading={isApiCallInprogress}
        // rows={tableRows.slice((page) * pageSize, (page + 1) * pageSize)}
        // pageSize={pageSize}
        // rowCount={tableRows?.length}
        // onPageChange={(newPage) => {
        //   setPage(newPage);
        // }}
        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // showCellRightBorder={true}
        // rowHeight={38}
        />
        {/* <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack> */}
      </div>

      {/* <MyComponent /> */}

      <Dialog
        open={EntityDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default", paddingBottom: "0px" }}>
          {viewMode ? "View Exchange Rate" : editMode ? "Edit Exchange Rate" : "Add Exchange Rate"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {!editMode && !viewMode && (
              <div style={EditPageContainerTransactionsList}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CommonButton
                    // sx={addBankTransactionAdd}
                    text="Add"
                    handleClick={handleAddRow}
                    icon={<AddIcon style={{ height: "18px" }} />}
                    hoverColor="#FF5A01"
                  />
                  <CommonButton
                    sx={{
                      backgroundColor: selectAll ? "#FF5A01" : "#CCCCCC",
                      color: selectAll ? "white" : "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "17px 10px",
                      borderRadius: "15px",
                      "&:hover": {
                        background: selectAll ? "#FF5A01" : "#CCCCCC",
                      },
                    }}
                    text="Delete"
                    handleClick={handleDeleteRow}
                    disabled={selectedRows.filter(Boolean).length === 0}
                    icon={<DeleteIcon style={{ height: "19px" }} />}
                  />
                </div>
              </div>
            )}
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddBankExchange
                  selectAll={selectAll}
                  handleInputChange={handleInputChange}
                  handleSelectAll={handleSelectAll}
                  rows={rows}
                  // handleBlur={handleBlur}
                  handleCheckboxChange={handleCheckboxChange}
                  validationStatus={validationStatus}
                  selectedRows={selectedRows}
                />
              </TableContainer>
            ) : (
              <>
                {rows?.map((row, index) => (
                  <Grid display={"flex"} flexDirection={"column"}>
                    <ViewAndEditEntity
                      row={row}
                      index={index}
                      viewMode={viewMode}
                      validationStatus={validationStatus}
                      editMode={editMode}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                ))}
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ?
            null :
            <>
              {isCreated &&
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  handleClick={() => handleReset()}
                  // handleClick={() => setOpenResetDialog(true)}
                  disabled={editMode}
                />
              }
              {
                editMode && (
                  <CommonButton
                    sx={editEntityUpdate}
                    text='Update'
                    handleClick={handleSubmit}
                    // disabled={isDisableUpdateBtn}
                    hoverColor="#FF5A01"
                  />)
              }
              {!editMode && !viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text='Submit'
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                />)
              }
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
