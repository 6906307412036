import React, { useState } from "react";
import { Box, Grid, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ResetPassword } from "../Services/api";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PasswordInput } from "./common";
import instance from "../redux/api";
import { CommonButton } from "./common/commonButton";

const changePasswordBackBtn = {
  backgroundColor: "white",
  color: "black",
  border: "1px solid",
  borderRadius: "28px",
  width: "128px",
};

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const DialogBox = {
  "& .MuiDialog-paper": {
    width: "80%",
    padding: "16px",
    overflowX: "hidden",
  }
}

const DialogTitleHeader = {
  backgroundColor: "white",
  color: "black",
  margin: "0",
  padding: "0",
  cursor: "default",
}

const DialogBoxButtons = {
  margin: "5px",
  display: "flex",
  justifyContent: "end",
  width: "100%",
}

const ChangePassword = ({ setOpen }) => {
  const navigate = useNavigate();
  const [formdata, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    rePassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    rePassword: false,
  });
  const [isError, setisError] = useState(false);

  function formdataValueChange(e) {
    const value = e.target.value.trimStart();
    setFormData({
      ...formdata,
      [e.target.name]: value,
    });
  }

  const handleShowPasswordToggle = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const isSubmitDisabled = () => {
    // Check if the new password and re-entered password match
    const passwordsMatch = formdata.newPassword === formdata.rePassword;

    // Check if the old password is not empty
    const isOldPasswordNotEmpty = formdata.oldPassword.trim() !== "";

    // Enable the button only if both conditions are met
    return !(passwordsMatch && isOldPasswordNotEmpty);
  };

  const handleFormSubmit = async () => {
    try {
      if (formdata.oldPassword && formdata.newPassword) {
        const body = {
          user_id: localStorage.getItem("userID"),
          oldpassword: formdata.oldPassword,
          password: formdata.newPassword,
          repassword: formdata.rePassword,
        };
        const { data } = await instance.post(ResetPassword, body);
        if (data?.success) {
          toast.success(data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          localStorage.clear();
          setOpen(false);
          navigate('', { replace: true });
        } else {
          toast.error(data?.message);
        }
      } else {
        setisError(true);
      }
    } catch (error) {
      setisError(true);
    }
  };

  ValidatorForm.addValidationRule(
    "isPasswordMatch",
    (value) => {
      return value === formdata.newPassword;
    },
    "Passwords do not match"
  );

  return (
    <>
      <Dialog
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        sx={DialogBox}
      >
        <DialogTitle
          style={DialogTitleHeader}
        >
          Change Password
        </DialogTitle>
        <hr style={{ width: "100%" }} />

        <Box
          className="main-box mar-t-24 white-bg pad-r-0 loginbox ddd"
          style={{ marginTop: "20px" }}
        >
          <Grid
            container
            spacing={3}
            className="main-grid justify-content-center"
          >
            <Grid
              item
              md={12}
              className="main-title leftside align-self-center"
            >
              <title style={{ textAlign: "center" }}>RESET PASSWORD</title>
              <ValidatorForm onSubmit={handleFormSubmit} onError={() => null}>
                <label>Old Password *</label>
                <PasswordInput
                  handleToggle={() => handleShowPasswordToggle("oldPassword")}
                  value={formdata?.oldPassword}
                  handleChange={(e) => formdataValueChange(e)}
                  name="oldPassword"
                  width="100%"
                  placeholder="Enter Old Password"
                  isVisible={showPassword.oldPassword}
                  isExistingPassword={true}
                />

                <label>New Password *</label>

                <PasswordInput
                  name="newPassword"
                  placeholder="Enter New Password"
                  handleToggle={() => handleShowPasswordToggle("newPassword")}
                  value={formdata?.newPassword}
                  handleChange={(e) => formdataValueChange(e)}
                  isVisible={showPassword.newPassword}
                  width="100%"
                />

                <label>Re Enter New Password *</label>
                <PasswordInput
                  handleToggle={() => handleShowPasswordToggle("rePassword")}
                  value={formdata?.rePassword}
                  handleChange={(e) => formdataValueChange(e)}
                  name="rePassword"
                  width="100%"
                  placeholder="Re-enter New Password"
                  validators={["isPasswordMatch"]}
                  errorMessages={[
                    "Re-enter new password should match with new password",
                  ]}
                  isVisible={showPassword.rePassword}
                />

                <FlexBox
                  flexWrap="wrap"
                  sx={{
                    justifyContent: "space-between",
                    maxWidth: 400,
                  }}
                ></FlexBox>
                <FlexBox mb={2} style={{ alignSelf: "flex-start" }}>
                  <Box
                    position="relative"
                    className="login-button"
                    style={DialogBoxButtons}
                  >
                    <CommonButton
                      text="Back"
                      size="large"
                      handleClick={() => setOpen(false)}
                      sx={changePasswordBackBtn}
                    />
                  </Box>
                  <Box
                    position="relative"
                    className="login-button"
                    style={{
                      margin: "5px",
                      cursor: isSubmitDisabled() ? "default" : "pointer",
                    }}
                  >
                     <CommonButton
                      text="Submit"
                      size="large"
                      type="submit"
                      sx={{
                        backgroundColor: isSubmitDisabled()
                          ? "#f3f3f3"
                          : "#FF5A01",
                        borderRadius: "28px",
                        width: "128px",
                        color: isSubmitDisabled() ? "#ffffff" : "#000000",
                      }}
                      disabled={isSubmitDisabled()}
                      hoverColor='#FF5A01'
                    />
                  </Box>
                </FlexBox>
                {isError ? (
                  <div className="errors">Invalid Login Details</div>
                ) : (
                  ""
                )}
              </ValidatorForm>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default ChangePassword;
