import React, { useEffect, useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormLabel,
    Grid,
    IconButton,
    Input,
    TextField,
    Card,
    Typography,
    TextareaAutosize,
    Box,
  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CommonButton } from "../common/commonButton"
import { toast } from "react-toastify";
import { getAccountingMonths } from "../../Services/api";
import instance from "../../redux/api";
import format from "date-fns/format";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { useDispatch, useSelector } from "react-redux";



import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import Day.js
import { margin, width } from '@mui/system';

const dialogStyle = {
    maxWidth: "90vw",
    width: "90vw",
    margin: "auto",
};

const CloseIconStyle = {
    backgroundColor: "#3B3F44",
    color: "#ffff",
    height: "16px",
    width: "16px",
    padding: "7px 8px",
    borderRadius: "32px",
};

const EditPageContainer = {
    marginTop: 5,
    cursor: "default",
};

const EditpageContainerItems = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    padding: "15px",
  };

const AcountingMonthDateStyles = {
  height: "38px",
  backgroundColor: "white",
  padding: "0px 10px",
  color: "#000000de",
  border: "1px solid #b8b8b8",
  borderRadius: "5px",
  boxSizing: "border-box",
  fontSize: "15px",
  fontWeight: 400,
};

const resetButtonStyle = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const submitButtonStyle = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};

const AddEditAccountingMonth = ({operationMode, setIsOpenAccountingDialog , accountingMonth={}, updateAccountingMonth, latestMonth}) => {

  const [accountingMonthDropdown, setAccountingMonthDropdown] = useState([])

  useEffect(() => {
    let monthsList = []

    // const month = new Date(selectedMonth);
    // const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1);
    // Loop through each month from January (0) to December (11)
    for (let month = 0; month < 12; month++) {
      // Create a date object for the last day of the month
      const nextMonth = new Date(2025, month + 1, 1); // month + 1 to get the last day of the current month
      
      const lastDate = new Date(nextMonth - 1);
      // Format the display text as "Jan 2025"
      const displayText = lastDate.toLocaleString('default', { month: 'short', year: 'numeric' });
      const lastDateFormatted = format(lastDate, "yyyy-MM-dd");
      
      // Format the value as "yyyy-mm-dd"
      // const value = lastDate.toISOString().split('T')[0]; // Get the date in "yyyy-mm-dd" format
      
      monthsList.push({ text: displayText, value: lastDateFormatted });
    }
    setAccountingMonthDropdown(monthsList)
  }, [])

    const [formData, setFormData] = useState({
        accounting_month_date: "",
        accounting_month_start_date: "",
        accounting_month_end_date: "",
    })
    // const [accountingMonthDate, setAccountingMonthDate] = useState(null)

    const [isDisable, setIsDisable] = useState(true)

    const handleInputChange = (key, value) => {
      console.log("Change Value : ", key, value)
      console.log("Form Data : ", formData)
        setFormData({
            ...formData,
            [key]: value
        })
    }

    const handleSubmit = async () => {
      console.log("Submit : ", formData)
      try {
        setIsDisable(true)
        instance.post(`${getAccountingMonths}`, {...formData})
        .then((response) => {
          console.log("Create Accounting Month : ", response)
          toast.success("Accounting Month created successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsDisable(false)
          setIsOpenAccountingDialog(false)
          updateAccountingMonth()
          handleReset()
        }).catch((error) => {
          console.log("Error From API : ", error)
          if(typeof error.data.message === 'string'){
            toast.error(error.data.message ?? "Unable to create Accounting Month!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            })
          }
          else {

            error.data.message.forEach(message => 
              toast.error(message ?? "Unable to create Accounting Month!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
            )
          }
          setIsDisable(false)
        })
      }
      catch (err){
        console.error("Error : ", err)
        setIsDisable(false)
      }
    }

    const handleUpdate = () => {
      console.log("Update : ", formData)
      try {
        setIsDisable(true)
        instance.put(`${getAccountingMonths}${accountingMonth.id}/`, {...formData})
        .then((response) => {
          console.log("Update Accounting Month : ", response)
          toast.success("Accounting Month updated successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsDisable(false)
          setIsOpenAccountingDialog(false)
          updateAccountingMonth()
          handleReset()
        }).catch((error) => {
          console.log("Error From API : ", error)
          if(typeof error.data.message === 'string'){
            toast.error(error.data.message ?? "Unable to update Accounting Month!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            })
          }
          else {

            error.data.message.forEach(message => 
              toast.error(message ?? "Unable to update Accounting Month!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
            )
          }
          setIsDisable(false)
        })
      }
      catch (err){
        console.error("Error : ", err)
        setIsDisable(false)
      }
    }

    const handleReset = () => {
      setFormData({
        accounting_month_date: "",
        accounting_month_start_date: "",
        accounting_month_end_date: "",
      })
    }

    const handleDateChange = (e) => {
      console.log("Event : ", e);

    }

    const handleSelectMonth = (selectedMonth) => {
      if(selectedMonth){
        const month = new Date(selectedMonth);
        const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1);
        const lastDate = format(new Date(nextMonth - 1), "yyyy-MM-dd");
        handleInputChange("accounting_month_date", lastDate);
      }
    }

    const getNextDate = (dateString) => {
      // Create a new Date object from the input string
      const date = new Date(dateString);
        
      // Check if the date is valid
      if (isNaN(date)) {
          return ''; // Return empty if the date is invalid
      }

      // Add one day
      date.setDate(date.getDate() + 1);

      // return date;
      
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toISOString().split('T')[0];
      
      return format(date, "yyyy-MM-dd")

      return formattedDate;
    }


    const isEmptyObject = (obj) => Object.keys(obj).length === 0;

    useEffect(() => {
      if(operationMode === 'ADD' && !isEmptyObject(latestMonth)){
        const nextDate = getNextDate(latestMonth.accounting_month_end_date)
        console.log("Next Date : ", nextDate, latestMonth)
        setFormData({
          ...formData, accounting_month_start_date: nextDate
        })
      }
    }, [latestMonth])

    console.log("formData : ", formData)

    useEffect(() => {
      if(operationMode === 'EDIT'){
        setFormData({
          accounting_month_date: accountingMonth.accounting_month_date,
          accounting_month_start_date: accountingMonth.accounting_month_start_date,
          accounting_month_end_date: accountingMonth.accounting_month_end_date,
        })
      }
    }, [accountingMonth])

    useEffect(() => {
      setIsDisable(
        !formData.accounting_month_date ||
        !formData.accounting_month_start_date ||
        !formData.accounting_month_end_date
      )
    }, [formData])

    
    
  return (
    <div>
      <Dialog
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
          {operationMode === "EDIT"
            ? "Edit Accounting Month"
            : "Add Accounting Month"}
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenAccountingDialog(false)}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>

        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <Grid display={"flex"} flexDirection={"column"}>
            <Card sx={{boxShadow:'none', padding:'0'}} elevation={5} style={EditPageContainer}>
                <Grid container style={EditpageContainerItems} spacing={2}>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    >
                    <FormLabel>
                        Select Accounting Month
                    </FormLabel>
                    <CommonSelect
                      handleChange={(event, value, reason) =>
                        handleInputChange(
                          "accounting_month_date",
                          value.value
                        )
                      }
                      // Map through your list of dropdown values
                      // options={accountingMonthDropdown?.map(
                      //   (option) => option?.month
                      // )}
                      options={accountingMonthDropdown.map(option => ({ label: option.text, value: option.value }))}
                      
                      placeholder="Select Accounting Month"
                      value={formData?.accounting_month_date}
                    />
                    {/* <Input
                        type="date"
                        value={formData?.accounting_month_date}
                        onChange={(event) =>
                          handleInputChange("accounting_month_date", event.target.value)
                        }
                        style={AcountingMonthDateStyles}
                    /> */}
                    {/* <Box sx={{
                      '& .MuiFormControl-root':{
                        width:"100%",
                      },
                      '& .MuiIconButton-root':{
                          margin:'0',
                      },
                    }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker 
                            label={'"Accounting Month"'}
                            openTo="month"
                            views={['year', 'month']}
                            onChange={handleSelectMonth}
                            slotProps={{field:{clearable:true}}}
                            value={accountingMonthDate}
                            />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box> */}
                    </Grid>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    >
                    <FormLabel>
                        Select Accounting Month Start Date
                    </FormLabel>
                    <Input
                        type="date"
                        // value={ operationMode == 'ADD' ?  getNextDate(latestMonth.accounting_month_start_date) : formData?.accounting_month_start_date}
                        value={formData?.accounting_month_start_date}
                        onChange={(event) =>
                          handleInputChange("accounting_month_start_date", event.target.value)
                        }
                        disabled={operationMode === 'ADD' && !isEmptyObject(latestMonth)}
                        style={AcountingMonthDateStyles}
                    />
                    </Grid>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    >
                    <FormLabel>
                        Select Accounting Month End Date
                    </FormLabel>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker 
                          label="Select Accounting Month End Date" 
                          style={{width:90%}}
                          
                          format='DD-MMM-YYYY' />
                      </DemoContainer>
                    </LocalizationProvider> */}
                    <Input
                        type="date"
                        value={formData?.accounting_month_end_date}
                        onChange={(event) =>
                          handleInputChange("accounting_month_end_date", event.target.value)
                        }
                        style={AcountingMonthDateStyles}
                    />
                    </Grid>
                </Grid>
                  
                <Grid container style={EditpageContainerItems} spacing={2} justifyContent={"flex-end"}>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    justifyContent={"flex-end"}
                    >
                      <CommonButton
                        sx={resetButtonStyle}
                        text="Reset"
                        handleClick={() => handleReset()}
                      />
                      {operationMode === "EDIT" ? 
                      <CommonButton
                        sx={submitButtonStyle}
                        text='Update'
                        handleClick={handleUpdate}
                        hoverColor="#FF5A01"
                        disabled={isDisable}
                      /> :
                      <CommonButton
                        sx={submitButtonStyle}
                        text='Submit'
                        handleClick={handleSubmit}
                        hoverColor="#FF5A01"
                        disabled={isDisable}
                      />
                    }
                    </Grid>
                </Grid>
            </Card>
          </Grid>
        </DialogContent>

      </Dialog>
    </div>
  )
}

export default AddEditAccountingMonth
