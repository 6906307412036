import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch, TableRow, Box, Tooltip, Checkbox, Input } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MultiSelect } from "react-multi-select-component";
import "react-toastify/dist/ReactToastify.css";
import instance from "../../redux/api";
import { numberFormatter } from "../../utils/generalFunctions";
import { CustomLoader } from "../CustomLoader";
import { CommonButton } from "../common/commonButton";
import DialogBox from "../common/dialog";
import AddNewAllocation from "./AddNewAllocation";
import AllocationsTable from "./AllocationsTable";
import CashAllocationEdit from "./CashAllocationEdit";
import CorrectionTypes from "./CorrectionTypes";
import CorrectiveTransfer from "./CorrectiveTransfer";
import FileUpload from "./FileUpload";
import "./cash-allocation.scss";
import { useAllocationIssues, useAllocationList, useBankDetails, useCfi, useCorrectiveTrf, useCrossAllocation, useMsd, useRefund, useSearchParams, useTreasuryUpdate, useWriteOff } from "./hooks";
import { parseDate } from "./utils";
import { color } from "framer-motion";
import { set } from "lodash";
import { ContactlessOutlined } from "@mui/icons-material";

export default function DataTable() {
  const cashAllocationDetailsClose = "action-button filled pill danger";

  const { userData } = useSelector((state) => state?.user);

  const transactionId = useSearchParams("txn_id");
  const policyRef = useSearchParams("policy_ref");
  const [selectedRows, setSelectedRows] = useState([]);
  const [addingRecord, setAddingRecord] = useState(false);
  const [validationStatus, setValidationStatus] = useState([]);
  const [isEditingRecord, setIsEditingRecord] = useState(null);
  const { bankDetails, transactionDetails } = useBankDetails(transactionId);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [isAPIInProgress, setIsAPIInProgress] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(true);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const [triggerCTWorkflow, setTriggerCTWorkflow] = useState(false);
  const [triggerCorrectionTypesWorkflow, setTriggerCorrectionTypesWorkflow] = useState(false);
  const [currentAllocation, setCurrentAllocation] = useState(null);
  const [cashAllocationDetailsEditOpen, setCashAllocationDetailsEditOpen] = useState(false);

  // Contra Allocation States
  const [associatedAllocationActivities, setAssociatedAllocationActivities] = useState({})
  const [openContraAllocationConfirmDialog, setOpenContraAllocationConfirmDialog] = useState(false);
  const [contraAllocationDialogBody, setContraAllocationDialogBody] = useState("");
  const [contraComment, setContraComment] = useState("");
  const [contraReferencePolicies, setContraReferencePolicies] = useState([]);
  const [contraReferencePoliciesInputsValues, setContraReferencePoliciesInputsValues] = useState({});
  const [contraReferencePoliciesSelectOpen, setContraReferencePoliciesSelectOpen] = useState(false);
  const [contraReferencePoliciesSearchText, setContraReferencePoliciesSearchText] = useState("");

  // Global Loader
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log("contraReferencePolicies", contraReferencePolicies)
    console.log("contraReferencePoliciesInputsValues", contraReferencePoliciesInputsValues)
  }, [contraReferencePoliciesInputsValues, contraReferencePolicies])
  // hooks
  const {
    allocationList,
    setAllocationList,
    allocationsWithInitialStatus,
    saveAllocationData,
    deleteAllocationData,
    totalAllocatedAmount,
    totalReceivableAmount,
    loadData,
    apiProgressBar,
    setApiProgressBar,
  } = useAllocationList(transactionId);

  const handleContraReferencePoliciesCheckboxChange = (label, value) => {
    setContraReferencePolicies((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };




  const handleDeleteClick = async (id) => {
    try {
      setIsDeleteInProgress(true);
      const response = await deleteAllocationData(id);

      // close delete confirm dialog
      setOpenDeleteConfirmDialog(false);

      // Check if the deletion was successful
      if (response.status === 204 || response.status === 200) {
        // If successful, show a success toast
        toast.success("Item deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // If needed, update the state to reflect the deletion
        const updatedAllocations = allocationList.filter(
          (row) => row.id !== id
        );
        setAllocationList([...updatedAllocations]);
        setSelectedRows([]);
        setSelectedRowIndex(-1);
        setIsDeleteInProgress(false);
        // Perform any additional actions here
      } else {
        // If deletion was not successful, show an error toast
        toast.error(`Error deleting item: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsDeleteInProgress(false);
      }
    } catch (error) {
      // If an error occurs during deletion, show an error toast
      toast.error(`Error deleting item: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsDeleteInProgress(false);
    }
  };

  const displayToastMessage = (message, type = "error") => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  }

  // Trigger Auto Initiation of Corrective Transfer
  async function isAutoCorrectiveTransferTriggerd(allocationDetails) {
    try {
      let correctiveTransfers = null;
      try {
        // API Call to get corrective transfer details for allocation
        correctiveTransfers = await instance.get(
          `bankmanagement/get_corrective_by_allocation_id?cashallocation_id=${allocationDetails?.id}`
        );
      } catch (error) {
        if (error.status != 404) {
          toast.error("Auto Intiation of corrective transfer failed.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          return false;
        }
      }

      // Check if corrective Transfer already not exists for this allocation
      // Check if Producing Entity exists and not equal to receiving entity
      // Check if receivable amount is greater than 0
      if (
        allocationDetails?.allocation_status === "Allocated" &&
        (!correctiveTransfers || !correctiveTransfers?.data || correctiveTransfers?.data.length === 0) &&
        allocationDetails?.policy_fk?.Producing_Entity &&
        allocationDetails.policy_fk.Producing_Entity !== allocationDetails?.bank_txn?.bank_details?.entity_number &&
        allocationDetails?.receivable_amt != 0
      ) {
        setTriggerCTWorkflow(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Auto Intiation of corrective transfer failed.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

      return false;
    }
  }

  function isAutoCorrectionTypesTriggered(allocationDetails) {
    try {
      const old_allocation_status = allocationDetails?.audit_fields?.fields?.filter(field => field.field_name === "allocation_status")[0].old_value ?? ""
      if (
        allocationDetails?.allocation_status.startsWith("Query")
        && allocationDetails?.allocation_status !== old_allocation_status
      ) {
        setTriggerCorrectionTypesWorkflow(true);
        return true;
      } else {
        return false;
      }
    }
    catch (error) {
      toast.error("Auto Intiation of correction type failed.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return false;
    }
  }

  const handleSubmit = async ({ policy, ...item }) => {
    try {
      // Bank roe should be 1 when Bank_Currency_Code and original_ccy is same
      if (item?.bank_txn?.Bank_Currency_Code === item?.original_ccy) {
        item.bank_roe = 1.000000;
      }
      if (Number(item?.bank_roe) <= 0) {
        displayToastMessage("Bank ROE cannot be Zero");
        return
      }

      // Trigger UI Notification when allocation_status is Allocated and Receivable, Allocated amounts are Zero
      if (item?.allocation_status === "Allocated") {
        if (Number(item?.receivable_amt) === 0) {
          displayToastMessage("Receivable amount cannot be Zero");
          return
        }
        if (Number(item?.allocated_amt) === 0) {
          displayToastMessage("Allocated amount cannot be Zero");
          return
        }
        if (parseFloat(item?.receivable_amt) != parseFloat(item?.allocated_amt)) {
          displayToastMessage("Remaining Balance should be Zero");
          return
        }
        // Added by Gaurav on 28-11-2024
        item.allocatedby = userData.user_name;
        item.allocation_date = parseDate(new Date());
      }

      setCurrentAllocation(item);

      // Update audit fields
      const { audit_fields: auditFields } = item;
      auditFields.fields[0].value = item?.policy_id;
      auditFields.fields[1].value = item?.allocation_status;
      auditFields.fields[2].value = item?.receivable_amt;
      auditFields.fields[3].value = item?.allocated_amt;
      auditFields.user_id = userData?.id;

      const payload = {
        ...item,
        bank_txn: item?.bank_txn?.id ?? 0,
        policy_fk: item?.policy_fk?.id ?? null,
        policy_pk: item?.policy_pk ?? item?.policy_fk?.id,
        policy_mf: item?.policy_mf ?? [],
        audit_fields: auditFields,
      };
      setIsAPIInProgress(true);
      const response = await saveAllocationData(payload);
      setIsAPIInProgress(false);

      if (response.status === 200 || response.status === 201) {
        let shouldReload = true;

        // Auto Initiation of Corrective transfer check
        const isAutoInitiationTriggered = await isAutoCorrectiveTransferTriggerd(item);
        if (isAutoInitiationTriggered) {
          shouldReload = false;
        }

        // Auto Initiation of Correction Type check
        const isCorrectionTypesAutoInitiationTriggered = isAutoCorrectionTypesTriggered(item);

        if (isCorrectionTypesAutoInitiationTriggered) {
          shouldReload = false;
        }

        toast.success("Item Updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        if (shouldReload) {
          await loadData();
        }
      }
    } catch (err) {
      console.error("Error:", err);
      setIsAPIInProgress(false);
      toast.error(err?.data?.msg || "Unable to save cash allocation data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const startAddingNewRow = () => {
    setAddingRecord(true);
  };

  const handleAddRow = (data, show_toast = true) => {
    if (show_toast) {
      toast.success("Successfully Added new allocation!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }

    if (data?.bank_txn) {
      loadData();
    } else {
      try {
        instance
          .get(
            `bankmanagement/get_transactions_by_txn_id/?txn_id=${encodeURIComponent(data?.bank_txn)}`
          )
          .then((response) => {
            let data = response.data;
            // disable the row then when the allocation status is "Allocated"
            data = data?.map((item) => {
              return {
                ...item,
                disabled: item?.allocation_status === "Allocated",
                audit_fields: {
                  user_id: userData?.id,
                  fields: [
                    {
                      field_name: "policy_id",
                      old_value: item?.policy_id,
                      value: item?.policy_id
                    },
                    {
                      field_name: "allocation_status",
                      old_value: item?.allocation_status,
                      value: item?.allocation_status
                    },
                    {
                      field_name: "receivable_amt",
                      old_value: item?.receivable_amt,
                      value: item?.receivable_amt
                    },
                    {
                      field_name: "allocated_amt",
                      old_value: item?.allocated_amt,
                      value: item?.allocated_amt
                    }
                  ],
                  txn_id: item?.audit_txn?.id,
                  audit_txn_name: "CASH_ALLOCATION"
                }
              }
            });
            setAllocationList([...data]);
          })
          .catch((error) => {
            console.error("Error fetching allocations:", error);
            toast.error("Unable fetch the added new allocation!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      } catch (err) {
        console.error("err", err);
      }
    }

    cancelAddRow();
  };

  const cancelAddRow = () => {
    setAddingRecord(false);
    setIsEditingRecord(null);
  };

  // Add state for modal visibility
  const [isPolicyErrorModalOpen, setIsPolicyErrorModalOpen] = useState(false);

  // Function to handle modal close
  const handleClosePolicyErrorModal = () => {
    setIsPolicyErrorModalOpen(false);
  };

  const handleDeleteRow = async () => {
    if (selectedRowIndex === -1) {
      toast.error(`Please select one row to delete`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (allocationList[selectedRowIndex]?.is_contra_allocation) {
      toast.error("Contra allocation cannot be deleted.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      return;
    }

    if (allocationList[selectedRowIndex]?.child_contra_id > 0) {
      toast.error("Can't delete allocation which has contra allocation", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      return;
    }

    try {
      const response = await instance.get(`bankmanagement/cash_allocation_activities/${allocationList[selectedRowIndex].id}/`);
      if (response.status === 200 && response.data.message === "Success") {
        setOpenDeleteConfirmDialog(true);
      } else {
        //setErrorText(response.data.data)
        setOpenDeleteConfirmDialog(true);
        // setIsPolicyErrorModalOpen(true);
      }
    } catch (error) {
      toast.error('Error while getting details of the policy', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleEditRow = () => {

    if (selectedRowIndex === -1) {
      toast.error(`Please select one row to edit`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (allocationList[selectedRowIndex]?.is_contra_allocation) {
      toast.error("Contra allocation cannot be edited.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      return;
    }

    if (allocationList[selectedRowIndex]?.child_contra_id > 0) {
      toast.error("Can't edit allocation which has contra allocation", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      return;
    }

    setCashAllocationDetailsEditOpen(true);
  }

  const toggleTransactionDetails = useCallback(() => {
    setShowTransactionDetails((t) => !t);
  }, [setShowTransactionDetails]);

  const handleDeleteCancelled = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteConfirmed = () => {
    const row = allocationList[selectedRowIndex];
    handleDeleteClick(row.id);
  };

  async function handleContraAllocationClick() {
    try {
      setIsLoading(true);
      let contraRow = allocationList[selectedRowIndex];
      const allocationId = contraRow.id;

      if (contraRow?.is_contra_allocation) {
        toast.error("Contra allocation cannot be added to an existing contra allocation entry.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setIsLoading(false);
        return;
      }

      if (contraRow?.child_contra_id > 0) {
        toast.error("Multiple contra allocations cannot be added to the same entry.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setIsLoading(false);
        return;
      }

      // Check if reference ploicies have been created
      const unAllocatedAllocations = allocationList.filter(
        (allocation) => allocation.allocation_status != "Allocated"
      );

      if (unAllocatedAllocations.length <= 0) {
        toast.error("All the existing policies are allocated. Please create reference policies for the contra allocation.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setIsLoading(false);
        return;
      }

      // Handle Workflows on Contra Allocation
      let associatedActivities = await getAssociatedAllocationActivities(allocationId);
      setAssociatedAllocationActivities(prevState => ({
        ...prevState,
        [allocationId]: associatedActivities
      }));

      setIsLoading(false);
      setOpenContraAllocationConfirmDialog(true);
    } catch (error) {
      setIsLoading(false);

      // If an error occurs during contra creation, show an error toast
      toast.error(`Error creating Contra Allocation`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  }

  async function getAssociatedAllocationActivities(allocationId) {
    try {
      // retrieve workflows list for the allocation
      const response = await instance.get(`bankmanagement/cash_allocation_activities/${allocationId}/`);
      if (response.status === 200) {
        let dialogueBody = (
          <>
            <b>Are you sure you want to create a Contra Allocation ?</b>
          </>
        );
        let associatedActivities = null;

        // success here defines no activities associated
        if (response.data.message != "Success") {
          associatedActivities = response.data.data;

          // Adjusting Dialoge
          const activityNameList = Object.keys(associatedActivities).map((key, index) => (
            <span key={index}>
              {key}
              <br />
            </span>
          ));
          dialogueBody = (<>
            The following activities are associated with the selected allocation : <br />
            {activityNameList}<br />
            <b>Are you sure you want to create a Contra Allocation?</b>
          </>)
        }

        setContraAllocationDialogBody(dialogueBody);
        return associatedActivities;
      } else {
        const errorMessage = "Error While Retrieving Associated Allocation Activities";
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  // Custom contra body to handle commenting
  const CustomContraBody = () => {
    // Filter allocations with 'not allocated' status
    const unAllocatedAllocations = allocationList.filter(
      (allocation) => allocation.allocation_status != "Allocated"
    );

    // Map allocations for dropdown
    const policyDropdownOptions = unAllocatedAllocations.map((allocation) => ({
      value: allocation.id,
      label: allocation?.policy_fk?.Policy_Line_Ref,
    }));
    const filteredContraReferencePoliciesOptions = policyDropdownOptions.filter((option) =>
      option.label.toLowerCase().includes(contraReferencePoliciesSearchText.toLowerCase())
    );
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10, // Gap applies only between contraAllocationDialogBody and Comments
          maxHeight: '80vh',
          overflow: 'auto'
        }}
      >
        {contraAllocationDialogBody && (
          <span>{contraAllocationDialogBody}</span>
        )}

        <div style={{ marginTop: "10px", width: "100%" }}>
          <p style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>
            Select Reference Policies
            <Tooltip
              title="Select the policies where contra amount has been mapped."
              style={{ marginLeft: "5px", cursor: "pointer" }}
            >
              🅘
            </Tooltip>
          </p>
          {/* 
          <MultiSelect
            options={policyDropdownOptions}
            value={contraReferencePolicies}
            onChange={setContraReferencePolicies}
            labelledBy="Select Reference Policies"
            overrideStrings={{
              selectSomeItems: "Select Reference Policies", // Placeholder
              allItemsAreSelected: "All Policies Selected",
              selectAll: "Select All",
            }}
            disableSearch={true}
            hasSelectAll={false}
          /> */}
          <div style={{ width: "100%", position: "relative" }} >
            <Button
              size="large"
              type="button"
              onClick={() =>
                setContraReferencePoliciesSelectOpen(!contraReferencePoliciesSelectOpen)
              }
              style={{
                width: "100%",
                height: "50px ",
                padding: "30px auto",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                backgroundColor: "white",
                color: "black",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Select Reference Policies</span>
              {contraReferencePoliciesSelectOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-chevron-up"
                >
                  <path d="m18 15-6-6-6 6" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-chevron-down"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              )}
            </Button>
            {contraReferencePoliciesSelectOpen && (
              <div
                style={{
                  position: "absolute",
                  width: "97%",
                  maxHeight: "150px",
                  overflow: "auto",
                  border: "3px solid #ccc",
                  boxShadow: "#00000085 2px 10px 20px 2px",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  top: 52,
                  padding: "5px",
                  zIndex: 1,
                }}
              >
                <Input
                  type="text"
                  size="small"

                  placeholder="Search..."
                  value={contraReferencePoliciesSearchText}
                  onChange={(e) => setContraReferencePoliciesSearchText(e.target.value)}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "block",
                  }}
                />
                <ul
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {filteredContraReferencePoliciesOptions.map((option, index) => (
                    <li
                      key={option.label}
                      style={{
                        padding: "3px",
                        height: '30px',
                        cursor: "pointer",
                        backgroundColor: contraReferencePolicies.includes(option.label)
                          ? "#e6e6e6"
                          : "transparent",
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <Checkbox
                        type="checkbox"
                        id={`contraReferencePoliciesSelectOpen${index}`}
                        checked={contraReferencePolicies.includes(option.value)}
                        onChange={() => handleContraReferencePoliciesCheckboxChange(option.label, option.value)}
                        style={{ marginRight: "8px" }}
                      />
                      <label
                        htmlFor={`contraReferencePoliciesSelectOpen${index}`}
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        {option.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginTop: "20px" }}>
            {contraReferencePolicies.length > 0 && (
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  margin: "20px 0",
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f4f4f4" }}>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Policy
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Receivable Amount <br />
                      <span style={{ fontWeight: "normal" }}>
                        [Total Amount To Allocate: {allocationList[selectedRowIndex].receivable_amt}]
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contraReferencePolicies.map((policy, index) => (
                    <tr
                      key={policy}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "12px",
                        }}
                      >
                        {filteredContraReferencePoliciesOptions.find(item => item.value === policy)?.label}
                        {/* {policy} */}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "12px",
                        }}
                      >
                        <Input
                          type="number"
                          value={contraReferencePoliciesInputsValues[policy]}
                          onChange={(e) =>
                            setContraReferencePoliciesInputsValues({
                              ...contraReferencePoliciesInputsValues,
                              [policy]: e.target.value,
                            })
                          }
                          style={{
                            width: "100%",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

        </div>

        <div>
          <textarea
            style={{
              width: "300px",
              height: "100px",
              marginTop: "20px",
            }}
            placeholder="Enter your comments..."
            value={contraComment}
            onChange={(e) => setContraComment(e.target.value)}
          />
        </div>
      </div>
    );
  }

  async function createContraAllocation() {
    setIsLoading(true);
    if (!contraComment.length) {
      toast.error("Comments are mandatory when creating a contra allocation.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      setIsLoading(false);
      return;
    }

    let contraRow = allocationList[selectedRowIndex];

    // // Validating referenced policies receivable amount with contra receivalble amount
    // let referencedPoliciesAmount = contraReferencePolicies.reduce((total, policy) => {
    //   // Find the allocation object by policyId
    //   const referencedAllocation = allocationList.find(item => item.id === policy.value);
    //   total += parseInt(referencedAllocation.receivable_amt);
    //   return total;
    // }, 0);

    let referencedPoliciesAmount = Object.values(contraReferencePoliciesInputsValues).map(value => parseFloat(value)).reduce((acc, curr) => acc + curr, 0);
    if (referencedPoliciesAmount != parseFloat(contraRow.receivable_amt)) {
      toast.error("Referenced policies receivable amount must be equal to the contra allocation receivable amount.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      setIsLoading(false);
      return;
    }

    // Updating referenced policies amounts
    try {
      await instance.post(`bankmanagement/updateContraAmountsForReferencedPolicies/`, { "contra_reference_policies_with_amount": contraReferencePoliciesInputsValues });
      console.log("Success while updating referenced policies amounts");
    }
    catch (error) {
      toast.error("Error while updating referenced policies amounts", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setIsLoading(false);
      return;
    }

    // Extracting referenced cash allocation ids
    const ReferencedContraAllocations = contraReferencePolicies.map(policy => policy.value);

    // Updating required columns
    contraRow.policy_pk = contraRow?.policy_fk?.id;
    contraRow.bank_txn = contraRow?.bank_txn?.id;
    contraRow.audit_txn = contraRow?.audit_txn?.id;
    contraRow.locked_by = contraRow?.locked_by?.id;
    contraRow.settlement_ccy = contraRow?.settlement_ccy?.id;
    contraRow.comment = contraComment;

    // Reassigning reversed amounts
    contraRow.unallocated_amt = -contraRow?.unallocated_amt;
    contraRow.allocated_amt = -contraRow?.allocated_amt;
    contraRow.receivable_amt = -contraRow?.receivable_amt;
    contraRow.installment_amount_org = -contraRow?.installment_amount_org;
    contraRow.referenced_contra_allocations = ReferencedContraAllocations.join(',');

    // Updating Contra Allocation Columns
    contraRow["is_contra_allocation"] = true;
    contraRow["parent_contra_id"] = contraRow.id;

    // Deleting keys which have foreign key references
    delete contraRow.id;
    delete contraRow.policy_fk;
    delete contraRow.created_by;
    delete contraRow.updated_by;

    contraRow.audit_fields.fields.map(field => {
      if (field.field_name == 'receivable_amt' || field.field_name == 'allocated_amt') {
        field.old_value = -field.old_value
        field.value = -field.value
      }
    });

    try {
      const response = await instance.post(`bankmanagement/cash_allocation/`, contraRow);
      const data = response.data;
      if (data) {
        // Handle Associated Allocation Activities on Contra Allocation
        // We will use it in future if required
        // handleAssociatedAllocationActivitiesOnContraAllocation(data.id);

        toast.success("Successfully Created Contra Policy", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setIsLoading(false);
        setOpenContraAllocationConfirmDialog(false);
        setContraReferencePolicies([]);
        setContraComment('');
        handleAddRow(data, false);
      } else {
        toast.error("Failed to Create Contra Policy", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setIsLoading(false);
        setOpenContraAllocationConfirmDialog(false);
        setContraReferencePolicies([]);
        setContraComment('');
        loadData();
      }

    } catch (error) {
      toast.error("Failed to Create Contra Policy", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

      setIsLoading(false);
      console.error("Error creating contra policies:", error);
      setOpenContraAllocationConfirmDialog(false);
      setContraReferencePolicies([]);
      setContraComment('');
      loadData();
    }
  }

  // Not using this for now, We will use it in future if required
  // Hooks initialize for Contra Entries
  // const { createContraWriteOff } = useWriteOff(allocationList[selectedRowIndex]);
  // const { createContraCrossAllocation } = useCrossAllocation(allocationList[selectedRowIndex]);
  // const { createContraRefund } = useRefund(allocationList[selectedRowIndex]);
  // const { createContraTreasuryUpdate } = useTreasuryUpdate(allocationList[selectedRowIndex]);
  // const { createContraCfi } = useCfi(allocationList[selectedRowIndex]);
  // const { createContraMSD } = useMsd(allocationList[selectedRowIndex]);
  // const { createContraCorrectiveTransfer } = useCorrectiveTrf(allocationList[selectedRowIndex], transactionDetails);
  // const { createContraCorrectionType } = useAllocationIssues({allocation : allocationList[selectedRowIndex], transaction: transactionDetails});
  // async function handleAssociatedAllocationActivitiesOnContraAllocation(allocationId) {
  //   try {
  //     const associatedActivities = associatedAllocationActivities[allocationId];

  //     // Creating Contra Activities for the contra allocation
  //     for (let activity in associatedActivities) {
  //       for (let currentActivity of associatedActivities[activity]) {
  //         switch (activity.toLowerCase()) {
  //           case "write-off":
  //             await createContraWriteOff(currentActivity, allocationId);
  //           case "cross-allocation":
  //             await createContraCrossAllocation(currentActivity, allocationId);
  //           case "refund":
  //             await createContraRefund(currentActivity, allocationId);
  //           case "tresury-update":
  //             await createContraTreasuryUpdate(currentActivity, allocationId);
  //           case "cfi":
  //             await createContraCfi(currentActivity, allocationId);
  //           case "msd-update":
  //             await createContraMSD(currentActivity, allocationId);
  //           case "correction-types":
  //             await createContraCorrectionType(currentActivity, allocationId);
  //           case "corrective-transfer":
  //             await createContraCorrectiveTransfer(currentActivity, allocationId);
  //           default:
  //             throw new Error("Invalid Activity Type");
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     toast.error("Failed to Create Contra Associated Policies", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //     console.error("Error Creating Contra Associated Policies: ", error);
  //   }
  // }

  return (
    <>
      {/* Page Loader */}
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1400, // Ensure loader appears on top
          }}
        >
          <CircularProgress size={60} style={{ color: "rgb(255, 90, 1)" }} />
        </Box>
      )}

      <div
        className={`cash-allocation-page ${showTransactionDetails ? "with-details" : "without-details"
          }`}
      >
        <div className="top-details-section">
          <div className="header">
            <div className="page-title-container">
              <span className="page-title">Cash Allocation Details</span>
            </div>
            <div className="page-actions" style={{ display: "flex" }}>
              <div>
                {" "}
                <div style={{ marginRight: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="cash-alloc-indicator historical"
                      style={{ marginRight: "10px" }} />{" "}
                    Historical
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="cash-alloc-indicator non-historical"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Non-Historical
                  </div>
                </div>
              </div>
              <CommonButton
                text="X Close Details"
                handleClick={() => window.history.back()}
                className={cashAllocationDetailsClose}
              />
            </div>
          </div>
          <div className="transaction-details">
            <div className="transaction-details-header">
              <span className="switch-container">
                <span className="page-title">View Bank Details</span>
                <Switch
                  inputProps={{ "aria-label": "Show Transaction Details" }}
                  onChange={toggleTransactionDetails}
                  checked={showTransactionDetails}
                />
                <span className="top-amount-details">
                  Total Receivable Amount:{" "}
                  <span>
                    {numberFormatter(transactionDetails.Receivable_Amount)}
                  </span>
                </span>
                <span className="top-amount-details">
                  Receivable Amount:{" "}
                  <span>
                    {numberFormatter(totalReceivableAmount)}
                  </span>
                </span>
                <span className="top-amount-details">
                  Total Allocated Amount:{" "}
                  <span>{numberFormatter(totalAllocatedAmount)}</span>
                </span>
                <span className="top-amount-details">
                  Variance:{" "}
                  <span>
                    {numberFormatter(transactionDetails.Receivable_Amount - totalReceivableAmount)}
                  </span>
                </span>
              </span>
              <FileUpload transaction={transactionDetails} />
            </div>
            {showTransactionDetails && (
              <div className="transaction-details-card">
                {bankDetails.map(({ label, value }) => (
                  <span
                    key={label}
                    className={`transaction-item ${label === "Payment reference" ? "w-50" : ""
                      }`}
                  >
                    <span className="transaction-item-label">{label}</span>
                    <span className="transaction-item-value" title={value}>
                      : {value}
                    </span>
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="action-container">
            <h4 className="">Remittance List</h4>
            <div className="table-actions">
              {["manager", "cc processor"].includes(userData?.role?.toLowerCase()) &&
                allocationList?.length > 0 && allocationsWithInitialStatus[selectedRowIndex]?.initialStatus === "Allocated" &&
                !allocationsWithInitialStatus[selectedRowIndex]?.is_contra_allocation &&
                (
                  <CommonButton
                    text="Create Contra Allocation"
                    handleClick={() => handleContraAllocationClick()}
                    sx={{ borderRadius: "30px", mr: 2 }}
                    hoverColor="#FF5A01"
                  />
                )}
              <CommonButton
                text="Add"
                handleClick={() => startAddingNewRow()}
                sx={{ borderRadius: "30px" }}
                hoverColor="#FF5A01"
              />
              <CommonButton
                text="Edit"
                handleClick={() => handleEditRow()}
                sx={{ borderRadius: 100 }}
                variant="outlined"
                disabled={
                  selectedRowIndex === -1 ||
                  userData?.role?.toLowerCase() !== "manager"
                }
                hoverColor="#FF5A01"
              />
              {allocationList?.length > 0 && (
                <CommonButton
                  text="Delete"
                  handleClick={() => handleDeleteRow()}
                  sx={{ borderRadius: "30px" }}
                  hoverColor="#FF5A01"
                  disabled={userData?.role?.toLowerCase() !== "manager"}
                />
              )
              }
            </div>
          </div>
        </div>

        <div className="table-section">
          <AllocationsTable
            allocations={allocationList}
            setAllocations={setAllocationList}
            allocationsWithInitialStatus={allocationsWithInitialStatus}
            validationStatus={validationStatus}
            setValidationStatus={setValidationStatus}
            handleSave={handleSubmit}
            transaction={transactionDetails}
            selectedRowIndex={selectedRowIndex}
            setSelectedRowIndex={setSelectedRowIndex}
            apiProgressBar={apiProgressBar}
            setApiProgressBar={setApiProgressBar}
            setIsEditingRecord={setIsEditingRecord}
            policyRef={policyRef}
          />
        </div>

        {isAPIInProgress && <CustomLoader />}

        <ToastContainer />

        {(addingRecord || isEditingRecord) && (
          <AddNewAllocation
            onSuccess={handleAddRow}
            onCancel={cancelAddRow}
            isEditingRecord={isEditingRecord}
            transaction={transactionDetails}
          />
        )}

        <DialogBox
          title="Delete Confirmation"
          body={`Are you sure you want to delete this policy ${allocationList?.[selectedRowIndex]?.policy_id}?`}
          confirmText="Delete"
          isOpen={openDeleteConfirmDialog}
          onCancel={handleDeleteCancelled}
          onConfirm={handleDeleteConfirmed}
          isDisableConfirm={isDeleteInProgress}
        />

        <DialogBox
          width="auto"
          title="Contra Allocation Confirmation"
          body={CustomContraBody}
          child={CustomContraBody}
          confirmText="Create"
          isOpen={openContraAllocationConfirmDialog}
          onCancel={() => {
            setContraReferencePolicies([]);
            setContraComment("");
            setOpenContraAllocationConfirmDialog(false);
            setContraReferencePoliciesInputsValues({});
            setContraReferencePolicies([]);
          }
          }
          onConfirm={createContraAllocation}
        />

        {/* Auto Initiation Of Corective Transfer */}
        {triggerCTWorkflow && (
          <CorrectiveTransfer
            allocation={allocationList[selectedRowIndex]}
            handleClose={() => {
              // Logic to close the dialog when the handleClose function is triggered
              setTriggerCTWorkflow(false);  // Or any method to control dialog visibility
            }}
            transaction={transactionDetails}
            currencies={[]}
            isAutoInitiation={true}
          />
        )}

        {/* Auto Initiation Of Correction Types */}
        {triggerCorrectionTypesWorkflow && (
          <CorrectionTypes
            allocation={allocationList[selectedRowIndex]}
            handleClose={() => {
              setTriggerCorrectionTypesWorkflow(false);
            }}
            transaction={transactionDetails}
            currencies={[]}
            isAutoInitiation={true}
          />
        )}

      </div>
      {/* Add the Dialog component for the policy error modal */}
      <Dialog
        open={isPolicyErrorModalOpen}
        onClose={handleClosePolicyErrorModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Policy cannot be updated as there are activities associated to it.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
            Kindly check !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePolicyErrorModal} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CashAllocationEdit loadData={loadData} policy={allocationList?.[selectedRowIndex]} isOpen={cashAllocationDetailsEditOpen} onCancel={() => setCashAllocationDetailsEditOpen(false)} />
    </>
  );
}

// const TriggerCorrectiveTransferWorkflow = ({ currentAllocation, transactionDetails, loadData }) => {
//   const { formData, setFormData, submit } = useCorrectiveTrf(currentAllocation, transactionDetails);

//   const triggerCTWorkflowAPI = async () => {
//     setFormData({
//       ...formData,
//       bank_curr: currentAllocation?.policy_fk?.Settlement_Ccy ?? null,
//     })
//     await submit();
//     loadData();
//   }

//   useEffect(() => {
//     triggerCTWorkflowAPI();
//   }, []);

//   return (
//     <>
//     </>
//   )
// };
