import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TxnCategoryTableMaintainance } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableContainer,
  Tooltip,
} from "@mui/material";
import DialogBox from "../common/dialog";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { AddTxnCategory } from "./AddTxnCategoy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ViewEntity } from "./ViewEntity";
import { CustomLoader } from '../CustomLoader';

// #region Styles
const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};

const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};

const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};
// #endregion

export default function TxnCategoryContainer() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const [row, setRow] = useState({ txn_category: "" });
  const [tableRows, setTableRows] = useState([]);
  const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
  const [txnCategoryDialog, setTxnCategoryDialog] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [validationStatus, setValidationStatus] = useState(true);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setTxnCategoryDialog(false);
    setViewMode(false);
    setValidationStatus(true);
    if (!viewMode) {
      setRow(
        {
         txn_category: ""
        },       
      );
    }
  };

  const handleActionButton = (rowData) => {
    const emptyRow = { txn_category: "" };
    if (rowData) {
      setRow(rowData);
      setViewMode(true);
    } else {
      // If no rowDataForView is provided, it means we are in add mode
      setRow(emptyRow);
      setIsCreated(true);
      setTxnCategoryDialog(true);
    }
    setTxnCategoryDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };

  const handleAddCancelled = () => {
    setOpen(false);
  };

  const handleAddConfirmed = async () => {
    setOpen(false);
    handleSubmit();
  };

  const handleReset = () => {
    const emptyRow = { txn_category: "" };
    setRow(emptyRow);
    setValidationStatus(true);
  };

  const columns = [
    { 
      field: "id", 
      headerName: "Serial No",
      flex: 0.3, 
      headerAlign: "center",
      valueGetter: (params) => params.api.getRowIndex(params.row.id) + 1, // Added valueGetter to generate serial numbers
    },
    {
      field: "txn_category",
      headerName: "Txn Category",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params?.value ?? "-",
    },
    {
      field: "updated_by",
      headerName: "Last Modified By",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params?.value ?? "-",
    },
    ...(userData?.role !== "CC Processor"
      ? [
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            flex: 0.8,
            cellClassName: "actions",
            getActions: ({ row }) => {
              let actionsList = [];
              if (UserAuthorisation?.["View"] === "Y") {
                actionsList.push(
                  <GridActionsCellItem
                    icon={
                      <Tooltip title="View">
                        <VisibilityIcon />
                      </Tooltip>
                    }
                    label="view"
                    className="textPrimary"
                    onClick={() => handleActionButton(row, false)}
                    color="inherit"
                  />
                );
              }
              return actionsList;
            },
          },
        ]
      : []),
  ];

  const loadData = async () => {
    try {
      setIsApiCallInprogress(true);
      const resp = await instance.get(TxnCategoryTableMaintainance);
      console.log("resp", resp);
      setIsApiCallInprogress(false);
      if (resp.status === 200) {
        // const sortedData = resp.data.sort((a, b) => b.id - a.id);
        setTableRows(resp.data);
      } else {
        setTableRows([]);
      }
    } catch (err) {
      setIsApiCallInprogress(false);
      console.error("err", err);
    }
  };

  const UserAuthorisation =
    userData?.user_permissions?.permissions_list["Admin Table Maintenance"];

  useEffect(() => {
    console.log("useEffect");
    loadData();
  }, []);

  const handleInputChange = (colName, value) => {
    // Create a new object for the updated row
    const updatedRow = { ...row, [colName]: value };
    setRow(updatedRow);
  };

  const validateRow = (row) => {
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (!row || !row.txn_category || row.txn_category.trim() === "") {
      fieldValidations.txn_category = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = { ...fieldValidations };
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };

  const handleValidation = () => {
    // Validate each row before submission
    const isRowValid = validateRow(row);

    if (!isRowValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fill all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let response;
      const rowPaylaod = { ...row, created_by: userData?.user_name ?? "" };
      response = await instance.post(TxnCategoryTableMaintainance, rowPaylaod);

      if (response) {
        toast.success("Submission successful !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };

  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>Transaction Category Table</span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <>
              <CommonButton
                text="Add"
                handleClick={() => handleActionButton()}
                sx={bankTransactionAddBtn}
                hoverColor="#FF5A01"
              />
            </>
          )}
        </div>
      </div>

      <div
        style={{
          height: "calc(100vh - 230px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          loading={isApiCallInprogress}
          rows={tableRows}
          columns={columns}
          rowCount={tableRows?.length}
          hideFooterPagination
          hideFooter
          showCellRightBorder={true}
          rowHeight={38}
        />
      </div>
      {/* <MyComponent /> */}
      <Dialog
        open={txnCategoryDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ cursor: "default", paddingBottom: "0px" }}
        >
          {viewMode ? "View Transaction Category" : "Add Transaction Category"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddTxnCategory
                  handleInputChange={handleInputChange}
                  row={row}
                  validationStatus={validationStatus}
                />
              </TableContainer>
            ) : (
              <>
                <Grid display={"flex"} flexDirection={"column"}>
                  <ViewEntity
                    row={row}
                    viewMode={viewMode}
                    validationStatus={validationStatus}
                    handleInputChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ? null : (
            <>
              {isCreated && (
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  handleClick={() => handleReset()}
                />
              )}
              {!viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text={isSubmitting ? <CustomLoader /> : "Submit"}
                  handleClick={() => handleValidation()}
                  hoverColor="#FF5A01"
                  disabled={isSubmitting}
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>

      <DialogBox
        title="Submit Confirmation"
        body="Are you sure you want to submit this record?"
        confirmText="Submit"
        isOpen={open}
        onCancel={handleAddCancelled}
        onConfirm={handleAddConfirmed}
      />
    </div>
  );
}