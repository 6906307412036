import { Box, Button, FormLabel, Grid, Typography, Stack } from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { CommonSelect, CommonPaginationContainer } from "../common";
import { CommonButton } from "../common/commonButton";
import { Months, Years } from "../../utils/constants"
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useStyles } from '../bankTransactions/bankTranscationContainerStyles'
import { getPaginationCount, customDateFormat } from '../CashAllocation/utils'
import { AgedReports, RunAgeDeptReport, DownloadPolicyData, GetAgedDeptYears } from "../../Services/api";
import format from "date-fns/format";
import instance from "../../redux/api";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PendingIcon from '@mui/icons-material/Pending';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { CircularProgress } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import { toast } from "react-toastify";


export const AgedReport = () => {
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);

  const [agedReportData, setAgedReportData] = useState([]);
  //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [downloadingData, setDownloadingData] = useState([]);
  const [isReportGenerating, setIsReportGenerating] = useState(false);
  const [processingData, setProcessingData] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const agedReportContainer = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    overFlow: "auto",
  };
  
  const agedReportText = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "400",
    marginTop: 5,
  };

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [yearMonths, setYearMonths] = useState([])
  const [years, setYears] = useState([])
  const [months, setMonths] = useState([]);

  const handleCancelButton = () => {
    setMonth(null)
    setYear(null)
    loadData(true)
  }

  const classes = useStyles();
  const handleVarianceResponse = (response) => {
    if (response.status === 200) {
      setPageState((old) => ({
        ...old,
        total: response?.data?.count,
        data: response.data.data || [],
      }));
    } else {
      setPageState((old) => ({
        ...old,
        total: response?.data?.count,
        data: [],
      }));
      setPaginationCount(0);
    }
  };

  useEffect(() => {
    loadYears();
    loadData();
  }, []);

  useEffect(() => {
    setPaginationCount(
      getPaginationCount(pageState?.total, pageState?.pageSize),
    );
  }, [pageState?.pageSize, pageState?.total]);

  useEffect(() => {
    loadData();
  }, [pageState?.pageSize, pageState?.page]);

  // Update months based on selected year
  useEffect(() => {
    if (year) {
      const uniqueMonths = yearMonths.find(data => data.year == year).months;
      
      const filteredMonthNames = uniqueMonths.map(monthValue => {
        return Months.find(m => m.value === monthValue);
      });

      setMonths(filteredMonthNames);
    } else {
      setMonths([]);
    }
  }, [year]);

  const handleRunButton = async (params) => {
    console.log("handleRunButton : ", params, params.row.id)
    setIsReportGenerating(true)
    setProcessingData([
      ...processingData,
      params.row.id,
    ])
    const response = await instance.post(`${RunAgeDeptReport}`, {
      file_name: params.row.file_name,
      file_month: params.row.month,
      file_year: params.row.year,
      id: params.row.id,
    }).then((response) => {
      let index = pageState.data.findIndex(record => record.id == params.row.id);
      pageState.data[index].status = 'Processing';
      setPageState((old) => ({
        ...old,
        data: [
          ...pageState?.data
        ],
      }));
      setProcessingData(processingData.filter(data => data !== params.row.id));
      setIsReportGenerating(false)
      toast.success("Agedept report generating in background!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }).catch((err) => {
      console.log(err);
      setProcessingData(processingData.filter(data => data !== params.row.id));
      setIsReportGenerating(false);
      toast.error("Something went wrong! : " + err.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  }

  const handleRefreshButton = async (params) => {
    
    setIsReportGenerating(true)
    setProcessingData([
      ...processingData,
      params.row.id,
    ])
    
    await instance.get(`${AgedReports}?id=${params.row.id}`).then((response) => {  
      let index = pageState.data.findIndex(record => record.id == params.row.id);
      const data = [...pageState.data]
      data[index] = response.data.data[0];

      setPageState((old) => ({
        ...old,
        data: [
          ...data
        ],
      }));

      setProcessingData(processingData.filter(data => data !== params.row.id));
      setIsReportGenerating(false)
    }).catch((err) => {
      console.log(err);
      setProcessingData(processingData.filter(data => data !== params.row.id));
      setIsReportGenerating(false);
    });
  }

  const handleDownloadButton = async  (params) => {
    const file_name = params.row.file_name
    setIsDownloadInProgress(true);
    setDownloadingData([
      ...downloadingData,
      params.row.id,
    ])
    try {
      instance
        .get(`${DownloadPolicyData}?file_name=${file_name}`, { responseType: "blob" })
        .then((response) => {
          const url = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          const monthName = Months.find(m => m.value == params.row.month).name;
          const fileName = `Aged_Dept_Report_${monthName}_${params.row.year}_${format(new Date(), "dd-MM-yyyy")}`
          link.setAttribute("download", `${fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setIsDownloadInProgress(false);
          setDownloadingData(downloadingData.filter(data => data!==params.row.id))
          toast.success("File has been downloaded successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }).catch((err) => {
          console.log(err);
          setIsDownloadInProgress(false);
          setDownloadingData(downloadingData.filter(data => data!==params.row.id))
          toast.error("File could not be downloaded", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    } catch (error) {
      setIsDownloadInProgress(false);
      console.log(error);
      toast.error("File could not be downloaded", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const columns = [
    {
      field: "file_name",
      headerName: "File Name",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "month",
      headerName: "Month",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => Months.find(month => month.value === params.row.month).name        
    },
    {
      field: "year",
      headerName: "Year",
      headerAlign: "center",
      width: 80,
    },
    {
      field: "no_of_records",
      headerName: "#Records",
      headerAlign: "center",
      width: 80,
    },
    {
      field: "uploaded_date_time",
      headerName: "Uploaded Date",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => <span>{ format(new Date(params.row.uploaded_date_time), "dd-MM-yyyy")}</span>
    },
    {
      field: "uploaded_by",
      headerName: "Uploaded By",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "status",
      headerName: "Fetch Data",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {
              params?.row?.status?.toLowerCase() === "processing" ? (
                isReportGenerating && processingData.includes(params.row.id) ? 
                    <CircularProgress size={35} style={{ color: "rgb(255, 90, 1)" }} />
                  :
                <>
                <PendingIcon
                  style={{ marginRight: 8 }}
                  titleAccess="Processing"
                />
                <ReplayIcon 
                  style={{ marginRight: 8, cursor: "pointer" }}
                  titleAccess="Refresh"
                  onClick={() => handleRefreshButton(params)}
                />
                </>
              ) : params?.row?.status?.toLowerCase() === "success" ? (
                <>
                  {(isDownloadInProgress && downloadingData.includes(params.row.id)) || 
                    (isReportGenerating && processingData.includes(params.row.id)) ? 
                    <CircularProgress size={35} style={{ color: "rgb(255, 90, 1)" }} />
                  :
                    <>
                      <CheckCircleIcon
                        style={{ marginRight: 8, cursor: "pointer", color: "green" }}
                        titleAccess="Success"
                      />
                      <DownloadIcon
                        style={{ marginRight: 8, cursor: "pointer" }}
                        onClick={() => handleDownloadButton(params)}
                        titleAccess="Download Age Dept Report"
                      />
                      <SettingsBackupRestoreIcon
                        style={{ marginRight: 8, cursor: "pointer" }}
                        onClick={() => handleRunButton(params)}
                        titleAccess="Re-run Age Dept Report"
                      />
                    </>
                  }
                </>
              )  : params?.row?.status?.toLowerCase() === "failed" ? (
                <>
                  {isReportGenerating && processingData.includes(params.row.id) ? 
                    <CircularProgress size={35} style={{ color: "rgb(255, 90, 1)" }} /> :
                    <>
                      <CancelIcon
                        style={{ marginRight: 8, cursor: "pointer", color:"red" }}
                        titleAccess="Failed"
                      />
                      <SettingsBackupRestoreIcon
                        style={{ marginRight: 8, cursor: "pointer" }}
                        onClick={() => handleRunButton(params)}
                        titleAccess="Re-run Age Dept Report"
                      />
                    </>
                  }
                </>
              )  : params?.row?.status?.toLowerCase() === "pending" ? (
                <>
                  {isReportGenerating && processingData.includes(params.row.id) ? 
                    <CircularProgress size={35} style={{ color: "rgb(255, 90, 1)" }} /> :
                    <PlayArrowIcon
                      style={{ marginRight: 8, cursor: "pointer" }}
                      onClick={() => handleRunButton(params)}
                      titleAccess="Run Age Dept Report"
                    />
                  }
                </>
              ) : ""
            }
          </>
        );
      },
    },
    {
      field: "last_run_by",
      headerName: "Last Run By",
      headerAlign: "center",
      width: 120,
    },
    {
      field: "last_run_time",
      headerName: "Last Run Time",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => (
        <span>{ params.row.last_run_time ? format(new Date(params.row.last_run_time), "dd-MM-yyyy HH:m") : "-" }</span>
      )
    },
    {
      field: "error_message",
      headerName: "Error Message (If Any)",
      headerAlign: "center",
      width: 250,
      renderCell: (params) => (
        <span style={{textWrap: 'auto'}}>{ params?.row?.status?.toLowerCase() === "failed" && params.row.error_message ? params.row.error_message : "N/A"}</span>
      )
    },
  ];



  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.total]);

  const loadYears = async () => {
    try {
      
      const response = await instance.get(`${GetAgedDeptYears}`);
      const data = response.data;
      setYearMonths(data)
      const yearsList = data.map(item => item.year);
      setYears(yearsList);
    } catch (error) {
      console.error("Error getting Year : ", error)
    }
  }


  const loadData = async (isClear = false) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${
        pageState?.pageSize
      }`;
      
      if(!isClear && month){
        queryParams = `${queryParams}&month=${month}`
      }
      
      if(!isClear && year){
        queryParams = `${queryParams}&year=${year}`
      }

      const response = await instance.get(`${AgedReports}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      handleVarianceResponse(response);
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  
  return (
    <Box style={agedReportContainer} >
      <div style={agedReportText}>
        <Typography
          variant="h3"
          style={{
            color: "#FF5A01",
            fontSize: "24px",
            margin: "10px 0px 15px 0px",
          }}
        >
          Agedebt Reports
        </Typography>
      </div>

      <Grid
        display={"flex"}
        flexDirection={"row"}
        gap={"10px"}
        container
      >
        <Grid item display={"flex"} flexDirection={"column"} gap={"8px"}>
          <FormLabel>Year</FormLabel>
          <CommonSelect
            placeholder="Select Year"
            value={year}
            handleChange={(event, value, reason) => setYear(value) }
            options={years || []}
            customStyles={{ width: "230px" }}
          />
        </Grid>

        <Grid item display={"flex"} flexDirection={"column"} gap={"8px"}>
          <FormLabel>Month</FormLabel>
          <CommonSelect
            placeholder="Select Month"
            value={months.find(option => option.value === month) || null}
            handleChange={(event, value, reason) => setMonth(value ? value.value : null) }
            options={months}
            getOptionLabel={(option) => option.name ?? option} // Use 'name' as the label for each option
            getOptionSelected={(option, value) => option.value === value} // Compare 'value' of option and selected value
            isOptionEqualToValue={(option, value) => option.value === value} // Ensure matching 'value'
            customStyles={{ width: "230px" }}
          />
        </Grid>
        
        <Grid
          item
          alignSelf={"space-between"}
          justifyContent={"space-between"}
          display={"flex"}
          flexDirection={"row"}
          gap={"10px"}
          paddingTop={"28px"}
        >
          <CommonButton
            text="Search"
            handleClick={() => loadData()}
            hoverColor="#EE3F00"
            disabled={
              month === null || year === null
            }
          />
          <CommonButton
            text="Clear"
            handleClick={handleCancelButton}
            hoverColor="#EE3F00"
          />
        </Grid>
      </Grid>

      <Grid item>
              <div
                style={{
                  height: "calc(100vh - 313px)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginTop:"8px"
                }}
              >
                <DataGrid
                  className={classes.pagination}
                  loading={pageState?.isLoading}
                  rows={pageState?.data ?? []}
                  columns={columns}
                  rowCount={pageState?.total}
                  keepNonExistentRowsSelected
                  disableSelectionOnClick
                  pagination
                  paginationMode="server"
                  getRowId={(row) => pageState?.data.indexOf(row)}
                  page={pageState.page - 1}
                  pageSize={pageState?.pageSize ?? 25}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    return setPageState((old) => {
                      setPaginationCount(
                        getPaginationCount(pageState?.total, newPageSize),
                      );
                      return { ...old, pageSize: newPageSize, page: 1 };
                    });
                  }}
                  sx={{
                    "& .MuiDataGrid-cellContent": {
                      textAlign: "start",
                    },
                    "& .MuiDataGrid-columnHeader[data-field='Comments']": {
                      minWidth: toggle?.isOpen
                        ? "209.137px !important"
                        : "209.137px !important",
                    },
                  }}
                />
                <Stack
                  style={{
                    position: "absolute",
                    bottom: "8px",
                  }}
                >
                  <CommonPaginationContainer
                    count={paginationCount + 1}
                    pageState={pageState}
                    setPageState={setPageState}
                  />
                </Stack>
              </div>
      </Grid>
    </Box>
  );
};
