import { Box, Button, FormLabel, Grid, Typography, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import { useState, useCallback, useEffect, useRef } from "react";
import instance from "../../redux/api";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import { policyFileColumnNames } from "./constants";
import { 
  PolicyFileUpload, 
  uploadRbsDetails,
  uploadSiriusData,
  uploadAonLedger,
  uploadMopMapping,
  getUpLoadedBankFiles,
  uploadROEFiles
} from "../../Services/api";
import axios from "axios";

const fileReader = (f, callback) => {
  return new Promise((resolve, reject) => {
    var name = f.name;
    console.log("file name", name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const wb = read(data, { type: "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const rowObject = utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
      });
      const headers = rowObject[0];
      let mandatoryColSet = new Set(policyFileColumnNames);
      let actualColSet = new Set(headers);
      const diff = mandatoryColSet.difference(actualColSet);
      if (diff.size !== 0) {
        console.log("invalid file");
        reject("Invalid file");
      } else {
        console.log("valid file");
        resolve("Valid file");
      }
    };
    reader.readAsArrayBuffer(f);
  });

  // const data = XLSX.utils.sheet_to_csv(ws, {header:1});
  // /* Update state */
  // console.log("Data>>>"+data);
};

export const StatementUpLoad = () => {
  //selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);

  //Local State
  const [formData, setFormData] = useState({
    name: "",
    file: null,
  });
  const [statementFieldsPrams, setStatementFieldsPrams] = useState({
    statement_type: "",
  });
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [fileTypeError, setFileTypeError] = useState(false);
  const inputRef = useRef();
  //Styles
  const statementUploadContainer = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    height: "525px",
    overFlow: "auto",
  };

  const statementUploadText = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "400",
    marginTop: 5,
  };

  const statementTypes = [
    { id: 1, label: "Bank Statement", api:getUpLoadedBankFiles, regEx:/^bank_statement_(\d{2})-(\d{2})-(\d{4})\.[a-zA-Z0-9]+$/ },
    { id: 2, label: "Remittance", api:PolicyFileUpload, regEx:/.*\b(\d{2})-(\d{2})-(\d{4})\b.*\.[a-zA-Z0-9]+$/ },
    { id: 3, label: "RBS Details", api:uploadRbsDetails, regEx:/^rbs_details_(\d{2})-(\d{2})-(\d{4})\.[a-zA-Z0-9]+$/ },
    { id: 4, label: "Sirius Point", api:uploadSiriusData, regEx:/^sirius_point_(\d{2})-(\d{2})-(\d{4})\.[a-zA-Z0-9]+$/ },
    { id: 5, label: "AON Ledger", api:uploadAonLedger, regEx:/^aon_ledger_(\d{2})-(\d{2})-(\d{4})\.[a-zA-Z0-9]+$/ },
    { id: 6, label: "MOP Mapping", api:uploadMopMapping, regEx:/^mop_mapping_(\d{2})-(\d{2})-(\d{4})\.[a-zA-Z0-9]+$/ },
    { id: 7, label: "ROE", api:uploadROEFiles, regEx:/^roe-(\d{2})-(\d{2})-(\d{4})\.[a-zA-Z0-9]+$/ }

  ];


  const isValidDate = (day, month, year) => {
    // Check if month is between 01 and 12
    if (month < 1 || month > 12) return false;

    // Check if day is valid for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate(); // Get number of days in the month
    if (day < 1 || day > daysInMonth) return false;

    return true;
  };

  const ValidateFileNames = (file) => {

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      
      const fileNamePattern = statementTypes.find(type => type.label === statementFieldsPrams?.statement_type).regEx;
      
      const match = fileName.match(fileNamePattern);
      
      if (match) {
        const day = parseInt(match[1], 10);
        const month = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);

        // Validate the date
        if (isValidDate(day, month, year)) {
          return {status: true}
        } else {
          return {status: false, message : 'Invalid date in file name. Please ensure the date is valid and in the format dd-mm-yyyy.'}
        }
      } else {
        return {status: false, message: 'Invalid file name format'}
      }
    }
  };

  const handleSubmitFileUpLoad = useCallback(
    async (e) => {
      e.preventDefault();
      setIsButtonDisable(true);
      const { file } = formData;

      if (file?.length > 0) {
        const upLoadpromises = file.map((currentFile) => {

          if(!["Bank Statement", "ROE"].includes(statementFieldsPrams?.statement_type)){
            const validate = ValidateFileNames(currentFile)
            if(!validate.status)
              return Promise.reject(validate.message)
          }

          if (statementFieldsPrams?.statement_type === "Remittance") {
            let formData = new FormData();
            formData.append("uploaded_by", userData.user_name);
            formData.append("policy_excel_file", currentFile);
            return instance.post(
              PolicyFileUpload,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
          else if (statementFieldsPrams?.statement_type === "ROE") {
            let formData = new FormData();
            formData.append("file", currentFile)
            formData.append("created_by", userData?.user_name ?? "")
            return instance.post(
              uploadROEFiles,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

          }
          else {
            let formData = new FormData();
            formData.append(
              "csrfmiddlewaretoken",
              "y1ClWtpYmU9QJfLOdlN2SlIcNAzuumg9xsvX9TxbnQvlYv6EKcnKt8X8wrJyntaH"
            );

            if (statementFieldsPrams?.statement_type) {
              formData.append(
                "statement_type",
                statementFieldsPrams.statement_type
              );
            }

            if(statementFieldsPrams?.statement_type == "Bank Statement")
              formData.append("bank_details", currentFile);
            else
              formData.append("file", currentFile);
            
            const apiEndpoint = statementTypes.find(type => type.label === statementFieldsPrams?.statement_type).api;

            return instance.post(
              apiEndpoint,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
        });

        const results = await Promise.allSettled(upLoadpromises);

        if (statementFieldsPrams.statement_type !== "Remittance" && statementFieldsPrams.statement_type !== "ROE") {
          let uploadedFileNamesList = [];
          results.forEach((result) => {
            if (result.status === "fulfilled") {  
              toast.success(
                `File uploaded successfully!`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            } else {
              if(typeof result.reason == 'string')
                toast.error(`${result.reason}`, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              else
                toast.error(`${result.reason.data.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
            }
          });

          // Update the state with all uploaded file Names
          setUploadedFileNames([...uploadedFileNamesList]);
        }
        else if (statementFieldsPrams.statement_type === "ROE") {
          if (results[0].status === "fulfilled") {
            const successMessage = results[0].value.data.message || 'File uploaded successfully!';
            toast.success(successMessage, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000
            })
          }
          else {
            toast.error(`Error in uploading file: ${results[0].reason.data.message}`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000
            })
          }
        }
        else {
          if (results[0].status === "fulfilled") {
            const successMessage = results[0].value.data.results || 'File uploaded successfully!';
            toast.success(successMessage, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            if(typeof results[0].reason == 'string')
              toast.error(`${results[0].reason}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            else
              toast.error(`${results[0].reason.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
          }
        }
      } else {
        alert("Please select files to upload.");
      }
      setStatementFieldsPrams((old) => ({ ...old, statement_type: "" }));
      setSelectedFiles([]);
      setIsButtonDisable(true);
      inputRef.current.files = [];
    },
    [formData, statementFieldsPrams]
  );

  useEffect(() => {
    if (statementFieldsPrams.statement_type === "ROE") {
      validateROETypeFile();
    }
    else {
      validatePolicyTypeFile();
    }
  }, [selectedFiles, statementFieldsPrams.statement_type]);

  const validatePolicyTypeFile = async () => {
    if (statementFieldsPrams?.statement_type === "Remittance") {
      setIsButtonDisable(true);
      if (selectedFiles.length > 1) { 
        toast.error("Multiple policy type files not allowed", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // setFileTypeError(true);
        setSelectedFiles([]);
        inputRef.current.files = [];
        setFormData({
          file: null,
        });
        setIsButtonDisable(true);
        return;
      }

      const result = await Promise.allSettled(
        selectedFiles.map((file) => fileReader(file))
      );
      if (result[0].status !== "fulfilled") {
        toast.error("Invalid file", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setFileTypeError(true);
        setIsButtonDisable(false);
      } else {
        setFileTypeError(false);
        setIsButtonDisable(false);
      }
    } else if (statementFieldsPrams?.statement_type === "Bank Statement") {
      if (selectedFiles.length > 0) {
        setIsButtonDisable(false);
        setFileTypeError(false);
      } else {
        setIsButtonDisable(true);
        setFileTypeError(false);
      }
    }
  };

  const validateROETypeFile = async () => {
    setIsButtonDisable(true);
    if (selectedFiles.length > 1) {
      toast.error("Multiple ROE type files not allowed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setSelectedFiles([]);
      setFormData({
        file: null,
        name: ""
      });
      setIsButtonDisable(true);
      return;
    }

    const file_name_regex = /^roe-\d{2}-\d{2}-\d{4}..*$/;
    const file = selectedFiles[0]
    if (!file) return;

    if (!file_name_regex.test(file.name)) {
      toast.error("File name is invalid.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setSelectedFiles([]);
      setFormData({
        file: null,
        name: ""
      });
      setIsButtonDisable(true);
      return;
    }
    else {
      const day = parseInt(file.name.substring(4, 6));
      const month = parseInt(file.name.substring(7, 9));
      const year = parseInt(file.name.substring(10, 14));

      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        if (month === 2 && day !== 29) {
          toast.error("File name is invalid. dd should be last day of month.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setSelectedFiles([]);
          setFormData({
            file: null,
            name: ""
          });
          setIsButtonDisable(true);
          return;
        }
      } else {
        if (month === 2 && day !== 28) {
          toast.error("File name is invalid. dd should be last day of month.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setSelectedFiles([]);
          setFormData({
            file: null,
            name: ""
          });
          setIsButtonDisable(true);
          return;
        }
      }
      if (
        month !== 2 &&
        !(
          (month === 1 && day === 31) ||
          (month === 3 && day === 31) ||
          (month === 4 && day === 30) ||
          (month === 5 && day === 31) ||
          (month === 6 && day === 30) ||
          (month === 7 && day === 31) ||
          (month === 8 && day === 31) ||
          (month === 9 && day === 30) ||
          (month === 10 && day === 31) ||
          (month === 11 && day === 30) ||
          (month === 12 && day === 31)
        )
      ) {
        toast.error("File name is invalid. dd should be last day of month.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedFiles([]);
        setFormData({
          file: null,
          name: ""
        });
        setIsButtonDisable(true);
        return;
      }
    }
    setIsButtonDisable(false);
    setFileTypeError(false);
  }

  const handleFileUpLoadInputChange = useCallback(
    (e) => {
      const { name, value, files } = e.target;
      const fileSizeLimit = 10485760;

      const allowedFileTypes = [
        // "application/pdf",
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel", //added for ROE
      ];

      if (name === "file" && files.length > 0) {
        const validFiles = [];
        Object.values(files).forEach((file) => {
          const { type, size, name } = file;
          if (!allowedFileTypes.includes(type)) {
            toast.error(name + "Unsupported File Format", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }

          if (size / fileSizeLimit > 1) {
            toast.error("File size should be less than or equal to 10 MB.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setFileTypeError(true);
          }
          if (name.length > 256) {
            toast.error("File name should be less than 256 characters.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
          allowedFileTypes.includes(type) &&
            size / fileSizeLimit <= 1 &&
            name.length <= 256 &&
            validFiles.push(file);
        });
        const selectedFilesList = [...selectedFiles, ...validFiles];
        setSelectedFiles(selectedFilesList);

        setIsButtonDisable(selectedFilesList.length === 0);
        setFileTypeError(false);

        setFormData((data) => ({
          ...data,
          [name]: selectedFilesList,
        }));
      }
    },
    [selectedFiles]
  );

  const handleCancelButton = () => {
    setFormData({
      file: null,
    });
    setStatementFieldsPrams({
      statement_type: "",
    });
    setUploadedFileNames([]);
    setSelectedFiles([]);
    inputRef.current.files = [];
    setIsButtonDisable(true);
    setFileTypeError(false);
  };

  const handleStatementTypeDropdown = (name, value, reason) => {
    if (reason === "clear") {
      setFormData({
        file: null,
      });
      setStatementFieldsPrams({
        statement_type: "",
      });
      setUploadedFileNames([]);
      setSelectedFiles([]);
      inputRef.current.files = [];
    }
    setStatementFieldsPrams({
      ...statementFieldsPrams,
      [name]: value ?? "",
    });
  };

  return (
    <Box style={statementUploadContainer}>
      <div style={statementUploadText}>
        <Typography
          variant="h3"
          style={{
            color: "#FF5A01",
            fontSize: "24px",
            margin: "10px 0px 15px 0px",
          }}
        >
          Statement Upload
        </Typography>
      </div>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
        xs={12}
        gap={"15px"}
        style={{ width: "250px" }}
        mb={"40px"}
      >
        <Grid item display={"flex"} flexDirection={"column"} gap={"8px"}>
          <FormLabel>Statement Type</FormLabel>
          <CommonSelect
            placeholder="Select Statement Type"
            value={statementFieldsPrams?.statement_type}
            handleChange={(event, value, reason) =>
              handleStatementTypeDropdown(
                "statement_type",
                value,
                reason,
                event
              )
            }
            options={statementTypes?.map((option) => option?.label) || []}
            customStyles={{ width: "230px" }}
          />
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} gap={"6px"}>
          <FormLabel>Select File</FormLabel>
          <div style={{ display: "flex" }}>
            <Button
              color="inherit"
              variant="outlined"
              component="label"
              style={{ width: "230px" }}
              disabled={statementFieldsPrams?.statement_type === ""}
            >
              Choose File
              <input
                type="file"
                hidden
                multiple={statementFieldsPrams?.statement_type === "Remittance Upload" }
                name="file"
                accept=".doc,.docx,.xlsx,.csv,.pdf"
                onChange={handleFileUpLoadInputChange}
                onClick={(e) => (e.target.value = null)}
                required
                style={{ borderBottom: "none" }}
              />
            </Button>
            <Tooltip title={
              <Typography fontSize={12} color={"#FFFFFF"}>
                {"Instructions for ROE file upload:"}
                <br />
                {"1. The file name should be in the format: roe-<dd-mm-yyyy>.xlsx."}
                <br />
                {"2. <dd> should be last day of the month."}
                <br />
                {"3. The column names must be 'month', 'Premium Currency', and 'ROE', and must be present in the same order in the file."}
              </Typography>
            }
            >
              <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#FF5A01", alignSelf: "end", marginLeft: "5px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                {"i"}
              </div>
            </Tooltip>
          </div>
        </Grid>

        <Grid
          item
          alignSelf={"space-between"}
          justifyContent={"space-between"}
          display={"flex"}
          flexDirection={"row"}
          gap={"10px"}
        >
          <CommonButton
            text="Clear"
            handleClick={handleCancelButton}
            hoverColor="#EE3F00"
            disabled={
              isButtonDisable &&
              !statementFieldsPrams?.statement_type &&
              !fileTypeError
            }
            style={{
              backgroundColor: "transparent",
              color:
                isButtonDisable &&
                  !statementFieldsPrams?.statement_type &&
                  !fileTypeError
                  ? "rgba(0, 0, 0, 0.26)"
                  : "#17191B",
              border: "1px solid #b9aeae",
            }}
          />
          <CommonButton
            text="Upload"
            handleClick={handleSubmitFileUpLoad}
            hoverColor="#EE3F00"
            disabled={
              isButtonDisable ||
              !statementFieldsPrams?.statement_type ||
              fileTypeError ||
              !selectedFiles.length
            }
          />
        </Grid>
      </Grid>

      {!isButtonDisable && selectedFiles?.length > 0 && (
        <Grid item>
          <div>
            <h3>Selected Files:</h3>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        </Grid>
      )}

      {fileTypeError && (
        <Typography color={"red"}>"Unsupported File Format"</Typography>
      )}

      <Grid item>
        <div>
          {uploadedFileNames?.length > 0 && (
            <div>
              <h3>Uploaded Files:</h3>
              <ul>
                {uploadedFileNames.map((fileName, index) => (
                  <li key={index}>{fileName}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Grid>
    </Box>
  );
};
